//import React, { useState, useContext, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { GrPowerReset } from "react-icons/gr";
import { HiPlus } from "react-icons/hi";
import { FaBell } from "react-icons/fa";
import "../Styles/Dashboard.css";
import { getMonth } from "../Utils/GetMonth";
import GlobalContext from "../Utils/GlobalContext";

import React, { useContext, useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import {
  getAppointmentplannerDetail,
  getMeetingDetailByDate,
  getMeetingDetailByID,
  getMeetingStatusCount,
  getUpcomingMeetings,
  getUpcomingMeetingsFromAdminConfirm,
  iuappointmentplanner,
} from "../config/api";
import { BsCalendarX, BsStopwatch } from "react-icons/bs";
import { MdEditCalendar } from "react-icons/md";
import { RiCalendarCheckFill } from "react-icons/ri";
import "../Styles/Profile.css";
import Table from "../Components/table/Index";

const Dashboard = () => {
  const [state, setState] = useState({
    AppointmentID: "0",
    FirstName: "",
    LastName: "",
    Mobile: "",
    EmailID: "",
    ConsultationInterest: "",
    MeetingDate: "",
    MeetingTime: "",
    Comments: "",
    errors: [],
  });

  const [count, setCount] = useState({
    TotalPending: 0,
    TotalComplete: 0,
    TotalToday: 0,
    TotalDeclined: 0,
  });
  const [data, setData] = useState([]);
  const [value, onChange] = useState([
    new Date(2023, 6, 1),
    new Date(2023, 6, 28),
  ]);
  const [dates, setDates] = useState();
  const columns = useMemo(
    () => [
      {
        Header: "Appoinment ID",
        accessor: "ID",
      },
      {
        Header: "Full Name",
        accessor: "FullName",
      },
      {
        Header: "Email",
        accessor: "EmailID",
      },
      {
        Header: "Mobile",
        accessor: "Mobile",
      },
      {
        Header: "Consultation Interest",
        accessor: "ConsultationInterest",
      },
      {
        Header: "Date",
        accessor: "MeetingDate",
      },
      {
        Header: "Time",
        accessor: "MeetingTime",
      },
      {
        Header: "Status",
        accessor: "Status",
      },
      // {
      //   Header: "action",
      //   Cell: ({ row }) => {
      //     return (
      //       <div className="d-flex">
      //         {/* <button
      //           className="border-0 bg-transparent text-primary d-flex align-items-center me-3"
      //           onClick={() => {
      //             navigate(
      //               `/app/listing/add/property-details?id=${row.original.PropertydetailID}&m=e`
      //             );
      //           }}
      //         >
      //           <i className="bx bx-plus me-1"></i> edit
      //         </button> */}
      //         <button
      //           className="border-0 bg-transparent text-danger d-flex align-items-center"
      //           onClick={() => {
      //             DeleteData(row);
      //           }}
      //         >
      //           <i className="bx bx-trash me-1"></i> delete
      //         </button>
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );
  useEffect(() => {
    // check_islogin();
    bindData_count();
    bindData();
  }, []);

  async function bindData() {
    await getUpcomingMeetings()
      .then((response) => {
        console.log("response", response);
        if (response && response.length > 0) {
          setData(response);
          let d = [];
          response.forEach((element) => {
            d.push(element.MeetingDate);
          });
          setDates(d);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const [dataa, setDataa] = useState([]);

  async function bindData_count() {
    await getMeetingStatusCount()
      .then((response) => {
        console.log("Count", response);
        if (response && response.length > 0) {
          setCount(response[0]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [currentTime, setCurrentTime] = useState(new Date().getHours());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getHours());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  let greeting;
  if (currentTime >= 0 && currentTime < 12) {
    greeting = "Good Morning";
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = "Good Afternoon";
  } else {
    greeting = "Good Evening";
  }

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var today = new Date();
  var date = String(today.getDate()).padStart(2, "0");
  var mm = monthNames[today.getMonth()];
  var yyyy = today.getFullYear();
  let day = weekday[today.getDay()];

  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);

  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      await iuappointmentplanner(state)
        .then((response) => {
          if (response[0].ID !== "exists") {
            alert("Your Appointment Saved Successfully");
            ResetState();
            window.location.reload(true);
            // navigate(
            //   `/app/package/add/itinerary${
            //     location.search.includes("m=e")
            //       ? location.search
            //       : "?id=" + response.ID
            //   }`
            // );
          } else {
            errors["EmailID"] = "This Email Already exists...";
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  async function ResetState() {
    setState({
      AppointmentID: "0",
      FirstName: "",
      LastName: "",
      Mobile: "",
      EmailID: "",
      ConsultationInterest: "",
      MeetingDate: "",
      MeetingTime: "",
      Comments: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;
    const Mobilenumber = /^[7-9][0-9]/;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    } else if (!state.LastName.match(regex_name)) {
      IsValid = false;
      errors["LastName"] = "Please Enter Valid Name";
    } else {
      errors["LastName"] = "";
    }
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    } else if (!state.Mobile.match(Mobilenumber)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state?.ConsultationInterest) {
      IsValid = false;
      errors["ConsultationInterest"] = "Consultation Interest is Required";
    }
    if (!state?.MeetingDate) {
      IsValid = false;
      errors["MeetingDate"] = "Meeting Date is Required";
    } else if (state.MeetingDate === "2023-07-26") {
      // meeting date is booked  the show error msg
      errors["MeetingDate"] = "This Date is booked please select another date ";
    } else {
      errors["MeetingDate"] = "";
    }

    if (!state?.MeetingTime) {
      IsValid = false;
      errors["MeetingTime"] = "Meeting Time is Required";
    } else if (state.MeetingTime === "11:52") {
      // meeting time  is not available  the show error msg
      errors["MeetingTime"] = "This time is booked please enter another time ";
    } else {
      errors["MeetingTime"] = "";
    }

    if (!state?.Comments) {
      IsValid = false;
      errors["Comments"] = "Comments is Required";
    } else {
      errors["EmailID"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  const [selectedDate, setSelectedDate] = useState(null);
  const [detail, setDetail] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [upcomingData, setUpcomingData] = useState([]);

  async function bindDataByDate(date) {
    const d = new Date(date);
    const dt = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    await getMeetingDetailByDate({ Date: dt }) // calendervar click kelyavr appointment show hotata
      .then((response) => {
        setDetail([]);
        console.log("datailaaa", response);
        if (response && Array.isArray(response) && response.length > 0) {
          setSelectedDate(response[0]?.MeetingDate);
          setDetail(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  console.log(selectedDate);
  const handleDateClick = async (date) => {
    setSelectedDate(
      date.getDate() + "-" + (date.getMonth()+1) + "-" + date.getFullYear()
    );
    bindDataByDate(date);
  };

  useEffect(() => {
    //getUpcomingMeetingsNotification();
  }, []);

  async function getUpcomingMeetingsNotification() {
    try {
      const response = await getUpcomingMeetingsFromAdminConfirm().then(
        (res) => {
          console.log("setUpcomingData", res);
          setUpcomingData(res.length);
        }
      );
    } catch (error) {
      alert(error);
    }
  }
  //const highlightedDates = ['2023-07-22', '2023-07-23', '2023-07-25'];

  function isSameDay(a, b) {
    const timestamp1 = Date.parse(new Date(a).toLocaleDateString());
    const timestamp2 = Date.parse(new Date(b).toLocaleDateString());

    if (!(isNaN(timestamp1) || isNaN(timestamp2))) {
      if (timestamp1 === timestamp2) {
        return true;
      }
    }
  }
  function tileClassName({ date, view }) {
    if (view === "month" && dates?.find((dDate) => isSameDay(dDate, date))) {
      return "highlight";
    }
  }
  return (
    <>
      <div className="Main_Container">
        <div className="notify-greeting-section">
          <div>
            <h3 className="dashboard-title">Abhita Land Solution</h3>
          </div>
          <div className="appointment-btn-top-wrapper">
            <div className="Dashborad_SubHeader">
              <div
                className="Align_Row make_Button"
                onClick={() => (window.location.href = "/app/booking")}
              >
                <HiPlus size={20} color="#fff" />
                <p>Make Appointment</p>
              </div>
            </div>

            <div className="notification-section">
              <FaBell size={40} className="notification-icon" />
              <div className="notification-cnt">
                <b>{count.TotalPending}</b>
              </div>
            </div>
          </div>
        </div>
        <div className=" dashboard-section">
          {/* <div className="row dashboard-section">
            <div className="col-lg-12 col-md-12 col-sm-12"> */}
              {/* <p className="pro-lower-title">Status</p> */}
              <div className="row">
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <a href="/app/list?t=1">
                    <div className="dashboard-card-col">
                      <div className="dashboard-card-wrapper">
                        <div>
                          <div className="dashboard-count">
                            {count.TotalToday}
                          </div>
                          <div className="dashboard-card-name">
                            Today's Meeting
                          </div>
                        </div>
                        <div className="card-icon-bg">
                          <MdEditCalendar size={30} className="card-icon" />
                        </div>
                      </div>

                      <div className="view-deatils bg-todays">View Details</div>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <a href="/app/list?a=Complete">
                    <div className="dashboard-card-col">
                      <div className="dashboard-card-wrapper">
                        <div>
                          <div className="dashboard-count">
                            {count.TotalComplete}
                          </div>
                          <div className="dashboard-card-name">
                            Complete Meeting
                          </div>
                        </div>
                        <div className="card-icon-bg">
                          <RiCalendarCheckFill
                            size={30}
                            className="card-icon"
                          />
                        </div>
                      </div>

                      <div className="view-deatils bg-complete ">
                        View Details
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <a href="/app/list?a=Pending">
                    <div className="dashboard-card-col">
                      <div className="dashboard-card-wrapper">
                        <div>
                          <div className="dashboard-count">
                            {count.TotalPending}
                          </div>
                          <div className="dashboard-card-name">
                            Pending Meeting
                          </div>
                        </div>
                        <div className="card-icon-bg">
                          <BsStopwatch size={30} className="card-icon" />
                        </div>
                      </div>

                      <div className="view-deatils bg-pending ">
                        View Details
                      </div>
                    </div>
                  </a>
                </div>

                <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 mb-3">
                  <a href="/app/list?a=Declined">
                    <div className="dashboard-card-col">
                      <div className="dashboard-card-wrapper">
                        <div>
                          <div className="dashboard-count">
                            {count.TotalDeclined}
                          </div>
                          <div className="dashboard-card-name">
                            Declined Meeting
                          </div>
                        </div>
                        <div className="card-icon-bg">
                          <BsCalendarX size={30} className="card-icon" />
                        </div>
                      </div>

                      <div className="view-deatils bg-decline ">
                        View Details
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            {/* </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <h3 className="session-title">
              Your Up Coming Sessions Until Next Week
            </h3>

            {/* <div className="card">
        <div className="card-datatable table-responsive">
          <div className="dataTables_wrapper dt-bootstrap5 no-footer"> */}
            <div className="pro-meet-table table-responsive-xxl">
              <table className="pro-sch-table">
                {/* <tr className="meet-heading">
                  <th>Session Title</th>
                  <th>Scheduled Date</th>
                  <th>Time</th>
                </tr> */}
                <tr>
                  {dataa ? <Table columns={columns} data={data} /> : <></>}
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div>
          <div className="row calender-make-btn-row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="dashboard-calender">
                <Calendar
                  onClickDay={(date) => handleDateClick(date)}
                  tileClassName={tileClassName}
                  selectRange={false}
                />
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              {/* <div className="Dashborad_SubHeader">
                <div
                  className="Align_Row make_Button"
                  onClick={() => (window.location.href = "/app/booking")}
                >
                  <HiPlus size={20} color="#fff" />
                  <p>Make Appointment</p>
                </div>
              </div> */}

              {/* Modal */}
              <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Book Appointment
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="Model-div-reset">
                      <h6 className="model-sub-heading ">
                        Fill the form to book an appointment
                      </h6>
                      <GrPowerReset
                        className="Reset-Button"
                        size={23}
                        onClick={ResetState}
                      />
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="book-label">First Name</label>
                          <input
                            className="form-control"
                            label="FirstName"
                            name="FirstName"
                            data-name="FirstName"
                            value={state.FirstName}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.FirstName}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="book-label">Last Name</label>
                          <input
                            className="form-control"
                            label="LastName"
                            name="LastName"
                            data-name="LastName"
                            value={state.LastName}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.LastName}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-lg-6">
                          <label className="book-label">Mobile Number</label>
                          <input
                            className="form-control"
                            label="Mobile"
                            name="Mobile"
                            data-name="Mobile"
                            value={state.Mobile}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.Mobile}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="book-label">Email ID</label>
                          <input
                            className="form-control"
                            label="EmailID"
                            name="EmailID"
                            data-name="EmailID"
                            value={state.EmailID}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.EmailID}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-lg-6">
                          <label className="book-label">Date</label>
                          <input
                            type="date"
                            className="form-control"
                            label="MeetingDate"
                            name="MeetingDate"
                            data-name="MeetingDate"
                            value={state.MeetingDate}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.MeetingDate}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="book-label">Time</label>
                          <input
                            className="form-control"
                            label="MeetingTime"
                            name="MeetingTime"
                            data-name="MeetingTime"
                            value={state.MeetingTime}
                            type="time"
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.MeetingTime}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-lg-12">
                          <label className="book-label">
                            Consultation Interest
                          </label>
                          <select
                            className="form-select interest-select"
                            name="ConsultationInterest"
                            onChange={handlechange}
                            value={state.ConsultationInterest}
                          >
                            <option value="0">Select</option>
                            <option value="Abhitaland">
                              Abhita Land Solutions
                            </option>
                            <option value="Abhitafinance">
                              Abhita Finance
                            </option>
                            <option value="Abhitaagro">
                              Abhita Agro Industries
                            </option>
                            <option value="AbhitaVAV">Abhita UAV</option>
                            <option value="AbhitaNBFC"> Abhita NBFC</option>
                            <option value="legaVac">LegaVac</option>
                          </select>
                          {state.errors ? (
                            <div className="error-msg">
                              {state.errors.ConsultationInterest}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="model-close-btn" onClick={handleClose}>
                    Close
                  </button>
                  <button
                    className="model-book-btn"
                    onClick={SaveData}
                    // onClick={handleClose}
                  >
                    Book Appointment
                  </button>
                </Modal.Footer>
              </Modal>

              {/* {selectedDate && (
                <div>
                  {/* <h2>Events for {selectedDate && selectedDate.toDateString()}</h2> 
                  {filteredEvents.length > 0 ? (
                    <ul>
                      {filteredEvents.map((event) => (
                        <li key={event.AppoinmentID}>{event.FullName}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>
                      No events found for{" "}
                      {selectedDate && selectedDate.toDateString()}.
                    </p>
                  )}
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div>
          <div className="appointment-details-title">
            <h5>Appointment Deatils :</h5>
            <h5 className="booked-date">{selectedDate}</h5>
          </div>
          <div>
            <div className="appointment-details">
              <div className="row">
                {detail?.map((item, index) => (
                  <div
                    className="col-xl-3 col-lg-3 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="appointment-details-inner">
                      <p>{item.FullName}</p>
                      <p>{item.ConsultationInterest}</p>
                      <p>{item.MeetingTime}</p>
                      <p className="appt-status">{item.Status}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
