//import React from 'react'
//import { Col, Container, Row } from 'react-bootstrap'
import "../Styles/Login.css";
import { ICON } from "../Utils";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
} from "../config/api";
import CryptoJS from "crypto-js";

function CustOtp() {
  // const Login = () =>
  // {
  const navi = useNavigate();

  const [state, setstate] = useState({
    Mobile: "",
    OTP: "",
  });
  const [pwdType, setpwdType] = useState("Password");
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(() => { }, []);
  const key = "XkhZG4fW2t2W";
  async function handleClick(e) {debugger
    e.preventDefault();
    const bytes = CryptoJS.AES.decrypt(sessionStorage.getItem("OTP"),key);
    const data =JSON.stringify(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
  if(state.OTP === data){    
    sessionStorage.setItem("ActivePage","dashboard");
    window.location.href = "/app/booking";
  }    
  }
  const navigate = useNavigate();
  const handleChangePassword = () => {
    navigate("/changePassword");
  };
  // changePassword
  return (
    <>
      <div className="Login_Container">
        <div className="container">
          <div className="form-div-wrapper">
            <div className="logoin-logo-div">
              <img src={ICON.Abhitalogo} className="Login_Logo" />
            </div>
            <h3 className="page-title">Abhita Appointment Planner</h3>
            <div>
              <h4 className="login-title">Visitors OTP</h4>

              <form className="login-form">
                <div className="input-div">
                  <label htmlFor="email">OTP </label>
                  <input
                    id="OTP"
                    type="text"
                    placeholder="Enter OTP"
                    required
                    name="OTP"
                    value={state.OTP}
                    onChange={handleChange}
                  />
                </div>

                
                <div className="btn-div">
                  <button
                    className="btn btn--form btn-login"
                    onClick={handleClick}
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustOtp;
