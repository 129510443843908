//import React from 'react'
//import { Col, Container, Row } from 'react-bootstrap'
import "../Styles/Login.css";
import { ICON } from "../Utils";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { getAdminLoginDetail, getClientLoginDetail } from "../config/api";

function Login() {
  // const Login = () =>
  // {
  const navi = useNavigate();

  const [state, setstate] = useState({
    EmailID: "",
    Password: "",
  });
  const [pwdType, setpwdType] = useState("Password");
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(() => {}, []);
  async function handleClick(e) {
    e.preventDefault();

    await getAdminLoginDetail(state)
      .then((response) => {
        console.log(response);
        if (response[0]?.length > 0) {
          sessionStorage.setItem("AdminID", response[0][0].ID);
          sessionStorage.setItem("EmailID", response[0][0].EmailID);
          sessionStorage.setItem("Type", response[0][0].Type);
          sessionStorage.setItem("ActivePage","dashboard");
          window.location.href = "/app/dashboard";
        } else {
          alert("EmailID Not Found \nPlease Contact To Admin");
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  const navigate = useNavigate();
  const handleChangePassword = () => {
    navigate("/changePassword");
  };
  // changePassword
  return (
    <>
      <div className="Login_Container">
        <div className="container">
          <div className="form-div-wrapper">
            <div className="logoin-logo-div">
              <img src={ICON.Abhitalogo} className="Login_Logo" />
            </div>
            <h3 className="page-title">Abhita Appointment Planner</h3>
            <div>
              <h4 className="login-title">Admin Login</h4>
              <form className="login-form">
                <div className="input-div">
                  <label htmlFor="email">Email </label>
                  <input
                    id="EmailID"
                    type="email"
                    placeholder="me@example.com"
                    required
                    name="EmailID"
                    value={state.EmailID}
                    onChange={handleChange}
                  />
                </div>

                <div className="input-div">
                  <label htmlFor="password">Password </label>
                  <input
                    id="Password"
                    placeholder="password"
                    required
                    type="password"
                    name="Password"
                    value={state.Password}
                    onChange={handleChange}
                  />
                </div>
                <div className="btn-div">
                  <button className="btn btn--form" onClick={handleClick}>
                    Log in
                  </button>
                </div>
              </form>
            </div>
            {/* <div className="forgot-pwd-btn">
            <p onClick={handleChangePassword}>Forgot Password?</p>
          </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
