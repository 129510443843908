import React, { useState } from "react";
import "../Styles/Applayout.css";
import AppSidebar from "./AppSidebar";
import { Outlet } from "react-router-dom";
import AdminSidebar from "./AdminSidebar";
import { TbLogout } from "react-icons/tb";
import { BiMenuAltRight } from "react-icons/bi";
import { ICON } from "../Utils";
import { FiSettings } from "react-icons/fi";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BsBookmarkPlus } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx";
import { RiFileList3Line } from "react-icons/ri";
const Applayout = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
    setShow(false);
  };

  const [activeItem, setActiveItem] = useState("dashboard");
  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
    setShow(false);
  };
  const navi = useNavigate();
  return (
    <>
      <div className="Main_Applayout">
        <div className="Layout">
          <div className="Side_Bar">
            <AppSidebar />
          </div>
          <div className="mb-nav-Bar">
            <div className="mb-header-wrapper">
              <div className="mb-header-top-logo">
                <img src={ICON.Abhitalogo} className="header_TopLogo" />
              </div>
              <div>
                <BiMenuAltRight
                  size={30}
                  className="menu-btn"
                  onClick={handleShow}
                />
              </div>
            </div>
          </div>

          <div className="App_Contant">
            <div className="App_Body">
              <Outlet />
            </div>
          </div>
        </div>
      </div>

      {/* mobile view offcanvas */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <div className="mb-menu-logo-bg">
              <a className="" href="/">
                <img
                  src={ICON.Abhitalogo}
                  alt="Abhita Logo"
                  className="offcanvas-planner"
                />
              </a>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="Sidebar_Nav_Div">
            {sessionStorage.getItem("Type") === "admin" ||
            sessionStorage.getItem("Type") === "receiptionist" ? (
              <Link
                to="/app/dashboard"
                className={
                  activeItem === "dashboard" ? "active" : "Sidebar_Nav"
                }
                onClick={() => handleItemClick("dashboard")}
              >
                <RxDashboard
                  className={
                    activeItem === "dashboard"
                      ? "activeIcon"
                      : "Sidebar_Nav_Icon"
                  }
                />
                <h6>Dashboard</h6>
              </Link>
            ) : (
              ""
            )}

            {sessionStorage.getItem("Type") === "receiptionist" ||
            sessionStorage.getItem("Type") === "user" ? (
              <Link
                to="/app/booking"
                className={activeItem === "booking" ? "active" : "Sidebar_Nav"}
                onClick={() => handleItemClick("booking")}
              >
                <BsBookmarkPlus
                  className={
                    activeItem === "booking" ? "activeIcon" : "Sidebar_Nav_Icon"
                  }
                />
                <h6>Booking</h6>
              </Link>
            ) : (
              ""
            )}

            {sessionStorage.getItem("Type") === "admin" ||
            sessionStorage.getItem("Type") === "receiptionist" ? (
              <Link
                to="/app/todays_appointment"
                className={
                  activeItem === "todaysappointment" ? "active" : "Sidebar_Nav"
                }
                onClick={() => handleItemClick("todaysappointment")}
              >
                <RiFileList3Line
                  className={
                    activeItem === "todaysappointment"
                      ? "activeIcon"
                      : "Sidebar_Nav_Icon"
                  }
                />
                <h6>My Appointment</h6>
              </Link>
            ) : (
              ""
            )}

            {sessionStorage.getItem("Type") === "admin" ||
            sessionStorage.getItem("Type") === "receiptionist" ? (
              <Link
                to="/app/setting"
                className={activeItem === "setting" ? "active" : "Sidebar_Nav"}
                onClick={() => handleItemClick("setting")}
              >
                <FiSettings
                  className={
                    activeItem === "setting" ? "activeIcon" : "Sidebar_Nav_Icon"
                  }
                />
                <h6>Setting</h6>
              </Link>
            ) : (
              ""
            )}

            {sessionStorage.getItem("Type") === "user" ? (
              <Link
                to="/app/appointment_list"
                className={
                  activeItem === "appointment_list" ? "active" : "Sidebar_Nav"
                }
                onClick={() => handleItemClick("appointment_list")}
              >
                <RiFileList3Line
                  className={
                    activeItem === "appointment_list"
                      ? "activeIcon"
                      : "Sidebar_Nav_Icon"
                  }
                />
                <h6>My Appointment</h6>
              </Link>
            ) : (
              ""
            )}
            {sessionStorage.getItem("Type") === "admin" ||
            sessionStorage.getItem("Type") === "receiptionist" ? (
              <Link
                to="/app/list"
                className={activeItem === "list" ? "active" : "Sidebar_Nav"}
                onClick={() => handleItemClick("list")}
              >
                <HiOutlineClipboardList
                  className={
                    activeItem === "list" ? "activeIcon" : "Sidebar_Nav_Icon"
                  }
                />
                <h6>List</h6>
              </Link>
            ) : (
              ""
            )}
            <Link
              className={activeItem === "logout" ? "active" : "Sidebar_Nav"}
              onClick={() => {
                handleItemClick("logout");
                handleShowModal();
              }}
            >
              <TbLogout
                className={
                  activeItem === "logout" ? "activeIcon" : "Sidebar_Nav_Icon"
                }
              />
              <h6>Logout</h6>
            </Link>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <Modal show={showModal} onHide={handleClose}>
        <div className="Logout_Main_div">
          <h5>Are you Sure want to Logout</h5>
          <p>
            you are also logged out from your personal cloud and any local data
            app open is this browser.
          </p>
          <div className="Logout_Button">
            <button className="button-28" onClick={handleCloseModal}>
              No
            </button>
            <button
              className="button-28"
              onClick={() => {
                sessionStorage.getItem("Type") === "user"
                  ? navi("/")
                  : navi("/login");
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Applayout;
