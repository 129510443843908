import axios from "axios";

export function getFileName(name, file) {
  let today = new Date(),
    date =today.getFullYear() +"-" +(today.getMonth() + 1) +"-" +today.getDate(),
    time =today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
  return (
    name.replace(/[^A-Z0-9]+/gi, "-") +
    "-" +
    date +
    time +
    "." +
    file.name.split(".")[1]
  );
}
//export const baseURL = "http://localhost:5001";
// export const baseURL = "https://api-appointment.abhitalandsolutions.com";
export const baseURL = "https://api.sunilshelke.in";
export const validateEmail = (email) => {
  const email_reg_exp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return email.match(email_reg_exp) === null ? false : true;
};
export const getNumberOfDays = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / oneDay) + 1;
};
export const getNumberOfNights = (start, end) => {
  const date1 = new Date(start);
  const date2 = new Date(end);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  return Math.round(diffInTime / oneDay);
};
export const validatePassword = (pass) => {
  return pass.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/);
};
export const onlyNumber = (number) => {
  let num_reg_exp = /^[0-9]+$/;
  return number.match(num_reg_exp);
};

export function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
}

export const check_islogin = () => {
  if (
    sessionStorage.getItem("UserID") !== null &&
    sessionStorage.getItem("UserID") !== undefined &&
    sessionStorage.getItem("UserID") !== ""
  ) {
    return true;
  } else {
    window.location.href = "/";
  }
};

export const icon_list = [
  { label: "Outline Location", value: "outline-location" },
];

export const convertTime = timeStr => {
  const [time, modifier] = timeStr.split(' ');
  let [hours, minutes] = time.split(':');
  if (hours === '12') {
     hours = '00';
  }
  if (modifier === 'PM') {
     hours = parseInt(hours, 10) + 12;
  }
  return `${hours}:${minutes}`;
};
export const smsTemplate=(type,date,time,otp="")=>{debugger
  let msg="";
  if(type==='Abhita Land Solutions Pvt Ltd'){
  msg= "Dear Customer, Thank you for connecting with us your appointment with Abhita Land solutions Pvt Ltd has been booked you shall report by Abhita on "+date+" "+time;
  }else if(type==='Abhita Finance Advisor Pvt Ltd'){
    msg= "Dear Customer, Thank You for connecting with us your appointment with Abhita Finance Advisors Pvt Ltd has been booked you shall report by Abhita on "+date+" "+time;
  }else if(type==="Abhita Agro Industries"){
    msg="Dear Customer,Thank you for connecting with us your appointment with Abhita Agro industries Pvt Ltd has been booked you shall report by Abhita on "+date+" "+time;
  }else if (type==="Abhita Films Production Pvt Ltd"){
    msg="Dear Customer, Thank You for connecting with us your appointment with Abhita Film production has been booked you shall report by Abhita on "+date+" "+time;
  }else if(type === "otp"){
    msg="Dear Customer, "+otp+" is your OTP for login for appointment with Mr. Sunil Shelke in Abhita.";
  }
  else{
    msg= "Dear Customer, Thank you for connecting with us your appointment with Abhita Land solutions Pvt Ltd has been booked you shall report by Abhita on "+date+" "+time;
  }

  return msg;
}

export const sendSMS=async (mobile,message) =>{
  let to = encodeURIComponent(mobile);
  let msg = encodeURIComponent(message);
  const result=await axios("https://webpostservice.com/sendsms_v2.0/sendsms.php?apikey=YWJoaXRhVDpTRFoyemtVSQ==&type=TEXT&sender=ABLAND&mobile=" + to + "&message=" + msg, {
    "method": "GET",      
  });
  return result;
}
export const smsService = {
  send: async function ({ to, msg }) {
    const response = await axios.get(
      "https://webpostservice.com/sendsms_v2.0/sendsms.php?apikey=YWJoaXRhVDpTRFoyemtVSQ==&type=TEXT&sender=ABLAND&mobile=" +
        to +
        "&message=" +
        msg
    );
    console.log("https://webpostservice.com/sendsms_v2.0/sendsms.php?apikey=YWJoaXRhVDpTRFoyemtVSQ==&type=TEXT&sender=ABLAND&mobile=" +
    to +
    "&message=" +
    msg)
    console.log(response);
    return response;
  },
  // save: async function ({ customerID, mobile, message, status, createdBy }) {
  //   const response = await axiosInstanceV2.post(
  //     "/ConServiceDetails/SMSResponse",
  //     { customerID, mobile, message, status, createdBy }
  //   );
  //   return response;
  // },
};
