import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "./Auth/Login";
import Applayout from "./Components/Applayout";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Admindashboard from "./Pages/Admindashboard";
import Booking from "./Pages/Booking";
import Profile from "./Pages/Profile";
import List from "./Pages/List";
import TodaysAppointment from "./Pages/TodaysAppointment";
import Setting from "./Pages/Setting";
import ClientProfile from "./Pages/ClientProfile";
import ChangePassword from "./Pages/ChangePassword";
import CustomerReg from "./Pages/customer/CustomerReg";
import CustLogin from "./Auth/CustLogin";
import CustOtp from "./Auth/CustOtp";
import AppointmentList from "./Pages/customer/AppointmentList";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/custreg" element={<CustomerReg />} />
            <Route path="/" element={<CustLogin />} />
            <Route path="/custotp" element={<CustOtp />} />
          <Route path="/app" element={<Applayout />}>
            <Route exact path="dashboard" element={<Dashboard />} />
            <Route exact path="admindashboard" element={<Admindashboard />} />
            <Route exact path="booking" element={<Booking />} />
            <Route exact path="list" element={<List />} />
            {/* <Route exact path='Profile' element={<Profile />} /> */}
            <Route
              exact
              path="todays_appointment"
              element={<TodaysAppointment />}
            />
            <Route
              exact
              path="appointment_list"
              element={<AppointmentList />}
            />
            <Route exact path="setting" element={<Setting />} />
            <Route exact path="clientprofile" element={<ClientProfile />} />
            
          </Route>

        </Routes>
      </Router>
    </>
  );
}

export default App;
