//import React from 'react'
import React, { useEffect, useState } from 'react'
import '../Styles/Setting.css'

import {
  AdminloginUpdate,
  ClientloginUpdate,
  getAdminPasswordChange
} from "../config/api";

const Setting = () => {

  const [data, setData] = useState({
    EmailID: '',
    OldPassword: '',
    Password: '',
    ConfirmPassword: ''
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleReset = () => {
    setData({
      EmailID: '',
      OldPassword: '',
      Password: '',
      ConfirmPassword: ''
    })
  }

  const handleChangePassword = async (e) => {

    const form = e.currentTarget;
    e.preventDefault();
    await getAdminPasswordChange(data)
    .then((response) => {
      if (response.length > 0 && response[0]?.EmailID !== '' && response[0]?.EmailID !== null) {
        //bindData();
        handleReset();
        alert("Changed Successfully");
      }
      else {
        alert("Try Again...");
      }
    })
    .catch((error) => {
      alert(error);
    });
  }

  console.log(data)

  return (
    <>
      <div className='Main_Container'>
        <h5>Setting</h5>
        <div className='Setting-form-div'>
          <h4>Change Login Credentials</h4>
          <div>
            <div>
              <h6>Email ID</h6>
              <input
                type='text'
                placeholder='Enter Email ID'
                className='Setting_inputfield'

                value={data.EmailID}
                onChange={handleChange}
                id="EmailID"
                //type="email"
                //required
                name="EmailID"
              />
            </div>
            <div>
              <h6>Old password</h6>
              <input type='text'
                placeholder='Old Password'
                className='Setting_inputfield'
                value={data.OldPassword}
                onChange={handleChange}
                name="OldPassword"
              />
            </div>
            <div>
              <h6>New password</h6>
              <input type='text'
                placeholder='New Password'
                className='Setting_inputfield'
                value={data.Password}
                onChange={handleChange}
                name="Password"
              />
            </div>
            <div>
              <h6>Confirm password</h6>
              <input type='text'
                placeholder='Confirm New Password'
                className='Setting_inputfield'
                value={data.ConfirmPassword}
                onChange={handleChange}
                name="ConfirmPassword"
              />
            </div>
            <div className='Button_div'>
              <button className='button-28' onClick={handleReset}>Reset</button>
              <button className='button-28' onClick={handleChangePassword}>Change</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Setting