import React, { useEffect, useState } from "react";
import "../../Styles/TodaysAppointment.css";
import { BiCheck } from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";
import {
  getUpcomingMeetingsFromAdminConfirm,
  UpdateLocationAndStatus,
  CancleMeetingStatusUpdate,
  getPendingMeetingsWherecondition,
  getUpdateStatusForMeeting,
  getMeetingDetailByUserID,
} from "../../config/api";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Pagination from "../../Components/table/Pagination";
const AppointmentList = () => {
  const [selectedData, setSelectedData] = useState({ ID: "0", Location: "0", Remark: "" });
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData,setcurrentTableData]=useState([]);
  let PageSize = 6;
  useEffect(() => {
    bindData();
  }, [currentPage]);

  const [state, setState] = useState({
    ID:sessionStorage.getItem("UserID")?sessionStorage.getItem("UserID"):"0",
    SearchText: "",
    Date: "0",
    Time: "0",
  });

  async function bindData() {
    await getMeetingDetailByUserID(state)
      .then((response) => {debugger
        if (response?.length > 0) {
          setData(response);
          const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
          setcurrentTableData(response.slice(firstPageIndex, lastPageIndex))
          // Window.location.relaod();
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const handleChange = (e) => {
    if(e.target.value){setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });}
    else{
      setState({
        ...state,
        [e.target.name]: '',
        errors: { ...state.errors, [e.target.name]: "" },
      });
    }
  };

  return (
    <>
      <div className="Main_Container ">
        <h5>Appointment List</h5>
        <div className="List_header">
          <div className="search-text-wrapper">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="search-text-div">
                  <label>Search</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="search by name,mobile,email"
                    name="SearchText"
                    onChange={handleChange}
                    value={state.SearchText}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="search-text-div date-time-div">
                  <label>Date</label>
                  {/* <input
                    className="form-control"
                    type="date"
                    placeholder="search by Date"
                    name="Date"
                    onChange={handleChange}
                    value={state.Date}
                  /> */}
                  <DatePicker
                    className="Form_input"
                    calendarClassName="tren-pembayaran__wrapper__datepicker"
                    name="Date"
                    //minDate={new Date()}
                    // maxDate={new Date(new Date().setMonth((new Date()).getMonth() + 2))}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Meeting Date"
                    selected={
                      state.Date && state.Date !== "0"
                        ? new Date(state.Date)
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        let d = new Date(date);
                        let dd =
                          d.getFullYear() +
                          "-" +
                          (d.getMonth() + 1) +
                          "-" +
                          d.getDate();
                        setState({
                          ...state,
                          Date: dd,
                        });
                      } else {
                        setState({
                          ...state,
                          Date: null,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="search-text-div date-time-div">
                  <label>Time</label>
                  {/* <input
                    className="form-control"
                    type="time"
                    placeholder="search by time"
                    name="Time"
                    onChange={handleChange}
                    value={state.Time}
                  /> */}
                  <DatePicker
                    className="Form_input"
                    calendarClassName="tren-pembayaran__wrapper__datepicker"
                    name="Time"
                    // minDate={new Date()}
                    // maxDate={new Date(new Date().setMonth((new Date()).getMonth()+2))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Meeting Time"
                    //selected={new Date("2013-08-27 2:50 PM")}
                    selected={
                      state.Time &&
                      state.Time !== "0" &&
                      state.Date &&
                      state.Date !== "0"
                        ? new Date(state.Date + " " + state.Time)
                        : null
                    }
                    onChange={(time) => {
                      debugger;
                      if (!state?.Date || state.Date === "0") {
                        alert("Meeting Date is Required");
                      } else {
                        var hours = time.getHours();
                        var minutes = time.getMinutes();
                        var strTime = hours + ":" + minutes;
                        setState({
                          ...state,
                          Time: strTime,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="appointment-search-btn">
                  <button
                    className="serach-btn bg-success"
                    onClick={() => bindData()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Todays-app-section">
          <div className="row">
            {currentTableData.map((u, index) => (
              <div
                className="col-xl-4 col-lg-6 col-md-12 col-sm-12"
                key={index}
              >
                <div className="todays-inner-wrapper">
                  <p>
                    <span>Name:</span>
                    {u.FullName}
                  </p>
                  <p>
                    <span>Mobile:</span>
                    {u.Mobile}
                  </p>
                  <p>
                    <span>Interest In:</span>
                    {u.ConsultationInterest}
                  </p>
                  <p>
                    <span>Date:</span>
                    {u.MeetingDate}
                  </p>
                  <p>
                    <span>Time:</span>
                    {u.MeetingTime}
                  </p>
                  <p>
                    <span>Location:</span>
                    {u.Location}
                  </p>
                  <p>
                    <span>Booking Location:</span>
                    {u.BLocation}
                  </p>
                  <p>
                    <span>Status:</span>
                    {u.Status}
                  </p>
                  <p>
                    <span>Additional Infomation:</span>
                    {u.Description}
                  </p>
                  <p>
                    <span>Remark:</span>
                    {u.Remark}
                  </p>
                </div>
              </div>
            ))}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentList;
