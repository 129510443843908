import React, { useState } from "react";
import "../../Styles/CustomerReg.css";
import { ICON } from "../../Utils";
import { iuusermaster } from "../../config/api";
import { useNavigate } from "react-router-dom";
function CustomerReg() {
  const [state, setState] = useState({
    ID: 0,
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    Password: "",
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  function validate() {
    let errors = {};
    let IsValid = true;
    // const Mobilenumber = /^[7-9][0-9]/;
    const Mobilenumber = /^\d{10}$/;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    } else if (!state.LastName.match(regex_name)) {
      IsValid = false;
      errors["LastName"] = "Please Enter Valid Name";
    } else {
      errors["LastName"] = "";
    }debugger
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    } else if (!Mobilenumber.test(state.Mobile)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID is Required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state?.Password) {
      IsValid = false;
      errors["Password"] = "Password is Required";
    }
    if (!state.ConfirmPassword) {
      IsValid = false;
      errors["ConfirmPassword"] = "password is required";
    }

    if (!(state.Password === state.ConfirmPassword)) {
      IsValid = false;
      errors["ConfirmPassword"] = "new and confirm password should be same";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }
  const navigate = useNavigate();
  const handleReset = () => {
    setState({
      ID: 0,
      FirstName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      Password: "",
      ConfirmPassword: "",
    });
  };
  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    if (validate()) {
      await iuusermaster(state).then((response) => {
        debugger;
        if (response[0].data === "exist") {
          //errors["EmailID"] = "This Email Already exists...";
          alert("Email ID Already Exists");
        } else {
          // alert("You are succesfully registered..!!");
          // handleReset();
          // window.location.href = "/";
          navigate("/");
        }
      });
    }
  };
  return (
    <>
      <div className="custReg-section">
        <div className="container cust-reg-container">
          <div className="cust-reg-bg-wrapper">
            <div className="custReg-logo">
              <img src={ICON.Abhitalogo} className="logo-img" />
            </div>
            <h3 className="cust-reg-title">Abhita Appointment Planner</h3>
            <h3 className="custReg-title">Visitors Registration</h3>
            <form className="custReg-form">
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="input-div">
                    <label htmlFor="email">First Name </label>
                    <input
                      id="FirstName"
                      type="text"
                      placeholder="First Name"
                      required
                      name="FirstName"
                      value={state.FirstName}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="req-errors">{state.errors.FirstName}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="input-div">
                    <label htmlFor="email">Last Name </label>
                    <input
                      id="LastName"
                      type="text"
                      placeholder="Last Name"
                      required
                      name="LastName"
                      value={state.LastName}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="req-errors">{state.errors.LastName}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="input-div">
                    <label htmlFor="password">Contact Number </label>
                    <input
                      id="Mobile"
                      placeholder="mobile"
                      required
                      type="text"
                      maxLength={10}
                      name="Mobile"
                      value={state.Mobile}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="req-errors">{state.errors.Mobile}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="input-div">
                    <label htmlFor="email">Email </label>
                    <input
                      id="EmailID"
                      type="email"
                      placeholder="me@example.com"
                      required
                      name="EmailID"
                      value={state.EmailID}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="req-errors">{state.errors.EmailID}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="input-div">
                    <label htmlFor="password">Password </label>
                    <input
                      id="Password"
                      placeholder="password"
                      required
                      type="password"
                      name="Password"
                      value={state.Password}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="req-errors">{state.errors.Password}</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <div className="input-div">
                    <label htmlFor="password">Confirm Password </label>
                    <input
                      id="ConfirmPassword"
                      placeholder="Confirm Password"
                      required
                      type="password"
                      name="ConfirmPassword"
                      value={state.ConfirmPassword}
                      onChange={handleChange}
                    />
                    {state.errors ? (
                      <div className="req-errors">
                        {state.errors.ConfirmPassword}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="bottom-section">
                  <div className="cust-login-liink-div">
                    <p>
                      Already registerd ? click here to{" "}
                      <span>
                        <a href="/">Log In</a>
                      </span>
                    </p>
                  </div>
                  <div className="btn-div">
                    <button
                      className="btn btn--form"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                    <button className="btn  btn-success " onClick={handleReset}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </form>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerReg;
