//import React from 'react'
//import { Col, Container, Row } from 'react-bootstrap'
import "../Styles/Login.css";
import { ICON } from "../Utils";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  getAdminLoginDetail,
  getClientLoginDetail,
  getUserLoginDetail,
  iuSMSDetail,
} from "../config/api";
import {
  sendSMS,
  smsService,
  smsTemplate,
} from "../config/constant";
import CryptoJS from "crypto-js";

function CustLogin() {
  // const Login = () =>
  // {
  const navi = useNavigate();

  const [state, setstate] = useState({
    Mobile: "",
    Password: "",
  });
  const [pwdType, setpwdType] = useState("Password");
  const handleChange = (e) => {
    setstate({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(() => { }, []);

  const key = "XkhZG4fW2t2W";

  async function handleClick(e) {
    e.preventDefault();

    await getUserLoginDetail(state)
      .then((response) => {
        debugger
        console.log("cust log in", response);

        if (response[0]?.length > 0) {

          const a = smsTemplate('otp', '', '', response[0][0].otp);
          smsService.send({
            to: state.Mobile, // + data.Mobile,
            msg: a,
          }).then((res) => {
            if (res.data?.split(" | ")[0] === "SUBMIT_SUCCESS") {
              iuSMSDetail({
                CustomerID: 0,
                Mobile: state.Mobile,
                Message: a,
                Status: res.data,
                CreatedBy: 0,
              });
              // sendSMS(state.Mobile, a).then((res) => {
              //   console.log(res);
              //   iuSMSDetail({
              //     CustomerID: response[0][0].ID,
              //     Mobile: state.Mobile,
              //     Message: a,
              //     Status: res.data,
              //     CreatedBy: response[0][0].ID,
              //   }).then((response_a) => {
                  //if (response_a.length > 0) {
                    alert("Message Send To your mobile please check");
                    sessionStorage.setItem("UserID", response[0][0].ID);
                    sessionStorage.setItem("Mobile", response[0][0].Mobile);
                    sessionStorage.setItem("OTP", CryptoJS.AES.encrypt(response[0][0].otp, key));
                    sessionStorage.setItem("Type", "user");
                    sessionStorage.setItem("ActivePage", "dashboard");
                    window.location.href = "/custotp";
                  //}
                //});
              //});
            } else {
              alert("Message Not Sent. Please contact to admin");
              //alert("Message Send To your mobile please check");
              window.location.reload(true);
            }
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  const navigate = useNavigate();
  const handleChangePassword = () => {
    navigate("/changePassword");
  };
  // changePassword
  return (
    <>
      <div className="Login_Container">
        <div className="container">
          <div className="form-div-wrapper">
            <div className="logoin-logo-div">
              <img src={ICON.Abhitalogo} className="Login_Logo" />
            </div>
            <h3 className="page-title">Abhita Appointment Planner</h3>
            <div>
              <h4 className="login-title">Visitors Login</h4>

              <form className="login-form">
                <div className="input-div">
                  <label htmlFor="mobile">Mobile </label>
                  <input
                    id="Mobile"
                    type="text"
                    placeholder="Enter Mobile"
                    required
                    name="Mobile"
                    value={state.Mobile}
                    onChange={handleChange}
                  />
                </div>

                {/* <div className="input-div">
                  <label htmlFor="password">Password </label>
                  <input
                    id="Password"
                    placeholder="password"
                    required
                    type="password"
                    name="Password"
                    value={state.Password}
                    onChange={handleChange}
                  />
                </div>
                <p>
                  <a
                    href="#"
                    onClick={handleChangePassword}
                    className="forgot-pwd"
                  >
                    Forgot Password?
                  </a>
                </p> */}

                <div className="btn-div">
                  <button
                    className="btn btn--form btn-login"
                    onClick={handleClick}
                  >
                    Log in
                  </button>
                </div>

                {/* <div className="register-btn">
                  <p>
                    Create an account ?{" "}
                    <a
                      href="#"
                      onClick={() => (window.location.href = "/custreg")}
                    >
                      Register
                    </a>
                  </p>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustLogin;
