import React, { useEffect, useState } from "react";
import "../Styles/TodaysAppointment.css";
import { BiCheck } from "react-icons/bi";
import { RiCloseFill } from "react-icons/ri";
import {
  getUpcomingMeetingsFromAdminConfirm,
  UpdateLocationAndStatus,
  CancleMeetingStatusUpdate,
  getPendingMeetingsWherecondition,
  getUpdateStatusForMeeting,
} from "../config/api";
import { CSVLink, CSVDownload } from "react-csv";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Pagination from "../Components/table/Pagination";
const TodaysAppointment = () => {
  const [show, setShow] = useState(false);
  const [titlename, setTitleName] = useState("");
  const [hide, setHide] = useState(true);
  const [doneShow, setDoneShow] = useState(false);
  const [hideDrp, setHideDrp] = useState(true);
  const [showCancel, setShowCancel] = useState(false);
  const [selectedData, setSelectedData] = useState({
    ID: "0",
    Location: "0",
    Remark: "",
  });
  const [upcomingData, setUpcomingData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setcurrentTableData] = useState([]);
  let PageSize = 6;

  const [state, setState] = useState({
    SearchText: "",
    Date: "0",
    Time: "0",
  });

  async function bindData() {
    await getPendingMeetingsWherecondition(state)
      .then((response) => {
        if (response?.length > 0) {
          setData(response);
          const firstPageIndex = (currentPage - 1) * PageSize;
          const lastPageIndex = firstPageIndex + PageSize;
          setcurrentTableData(response.slice(firstPageIndex, lastPageIndex));
          // Window.location.relaod();
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.Location) {
      IsValid = false;
      errors["Location"] = "Location Required";
    } else {
      errors["Location"] = "";
    }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  const handleChange = (e) => {
    if (e.target.value) {
      setState({
        ...state,
        [e.target.name]: e.target.value,
        errors: { ...state.errors, [e.target.name]: "" },
      });
    } else {
      setState({
        ...state,
        [e.target.name]: "",
        errors: { ...state.errors, [e.target.name]: "" },
      });
    }
  };
  const handleChangeLocation = (event) => {
    const selectedValue = event.target.value;
    setSelectedData({ ...selectedData, [event.target.name]: selectedValue });
    // Update the selectedLocation property for the specific item
    // const updatedUpcomingData = [...data];
    // data[index].selectedLocation = selectedValue;
    // setUpcomingData(updatedUpcomingData);

    // // Check if the selected value is not '-- Select --'
    // if (selectedValue !== "-- Select --") {
    //   setShow(true);
    // } else {
    //   setShow(false);
    // }
  };

  const funShow = () => {
    setDoneShow(false);
    setHide(false);
  };

  const funDoneShow = (u, name) => {
    setShow(true);
    setTitleName(name);
    setSelectedData({ ID: u.ID, Location: "0", Remark: "" });
  };

  const funDecline = (u, name) => {
    setShow(true);
    setTitleName(name);
    setSelectedData({ ID: u.ID, Location: "0", Remark: "" });
    // setShowCancel(true);
    // setHide(false);

    // appointment.isCancel = true;

    // await getUpdateStatusForMeeting({
    //   ID: appointment.ID,
    //   Location: appointment.selectedLocation,
    //   Status: "Declined",
    // })
    //   .then((response) => {
    //     if (
    //       response.length > 0 &&
    //       response[0]?.ID !== "" &&
    //       response[0]?.ID !== null
    //     ) {
    //       bindData();
    //       alert("Declined");
    //     } else {
    //       alert("Try Again...");
    //     }
    //   })
    //   .catch((error) => {
    //     alert(error);
    //   });
  };
  console.log("currentTableData", currentTableData);
  const SaveData = async () => {
    debugger;
    if (titlename === "Accept" && selectedData.Location === "0") {
      alert("Please select location");
    } else if (titlename === "Declined" && selectedData.Remark === "") {
      alert("Please enter remark");
    } else {
      setDoneShow(true);
      setShow(false);
      await getUpdateStatusForMeeting({
        ID: selectedData.ID,
        Location: selectedData.Location,
        Remark: selectedData.Remark,
        Status: titlename === "Accept" ? "Accepted" : "Declined",
      })
        .then((response) => {
          if (
            response.length > 0 &&
            response[0]?.ID !== "" &&
            response[0]?.ID !== null
          ) {
            bindData();
            alert("Changed");
          } else {
            alert("Try Again...");
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  };
  useEffect(() => {
    bindData();
  }, [currentPage]);

  return (
    <>
      <div className="Main_Container ">
        <h5>Pending Appointment</h5>
        <div className="List_header">
          <div className="search-text-wrapper">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="search-text-div">
                  <label>Search</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="search by name,mobile,email"
                    name="SearchText"
                    onChange={handleChange}
                    value={state.SearchText}
                  />
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="search-text-div date-time-div">
                  <label>Date</label>
                  {/* <input
                    className="form-control"
                    type="date"
                    placeholder="search by Date"
                    name="Date"
                    onChange={handleChange}
                    value={state.Date}
                  /> */}
                  <DatePicker
                    className="Form_input"
                    calendarClassName="tren-pembayaran__wrapper__datepicker"
                    name="Date"
                    //minDate={new Date()}
                    // maxDate={new Date(new Date().setMonth((new Date()).getMonth() + 2))}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Meeting Date"
                    selected={
                      state.Date && state.Date !== "0"
                        ? new Date(state.Date)
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        let d = new Date(date);
                        let dd =
                          d.getFullYear() +
                          "-" +
                          (d.getMonth() + 1) +
                          "-" +
                          d.getDate();
                        setState({
                          ...state,
                          Date: dd,
                        });
                      } else {
                        setState({
                          ...state,
                          Date: null,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="search-text-div date-time-div">
                  <label>Time</label>
                  {/* <input
                    className="form-control"
                    type="time"
                    placeholder="search by time"
                    name="Time"
                    onChange={handleChange}
                    value={state.Time}
                  /> */}
                  <DatePicker
                    className="Form_input"
                    calendarClassName="tren-pembayaran__wrapper__datepicker"
                    name="Time"
                    // minDate={new Date()}
                    // maxDate={new Date(new Date().setMonth((new Date()).getMonth()+2))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Meeting Time"
                    //selected={new Date("2013-08-27 2:50 PM")}
                    selected={
                      state.Time &&
                      state.Time !== "0" &&
                      state.Date &&
                      state.Date !== "0"
                        ? new Date(state.Date + " " + state.Time)
                        : null
                    }
                    onChange={(time) => {
                      debugger;
                      if (!state?.Date || state.Date === "0") {
                        alert("Meeting Date is Required");
                      } else {
                        var hours = time.getHours();
                        var minutes = time.getMinutes();
                        var strTime = hours + ":" + minutes;
                        setState({
                          ...state,
                          Time: strTime,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                <div className="appointment-search-btn">
                  <button
                    className="serach-btn bg-success"
                    onClick={() => bindData()}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Todays-app-section">
          <div className="row">
            {currentTableData.map((u, index) => (
              <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12" key={index}>
                <div className="todays-inner-wrapper">
                  <p>
                    <span>Name:</span>
                    {u.FullName}
                  </p>
                  <p>
                    <span>Mobile:</span>
                    {u.Mobile}
                  </p>
                  <p>
                    <span>Interest In:</span>
                    {u.ConsultationInterest}
                  </p>
                  <p>
                    <span>Booking Location:</span>
                    {u.BLocation}
                  </p>
                  <p>
                    <span>Date:</span>
                    {u.MeetingDate}
                  </p>
                  <p>
                    <span>Time:</span>
                    {u.MeetingTime}
                  </p>

                  <div className="todays-appt-btn-wrapper">
                    <div className="">
                      {u.isConfirm ? (
                        <div className="todays-done-btn">
                          <button className="done-btn">
                            <BiCheck size={30} />
                            <p>Meeting Confirmed</p>
                          </button>
                        </div>
                      ) : (
                        <div className="todays-accept-btn">
                          <button
                            className="accept-btn"
                            onClick={() => funDoneShow(u, "Accept")}
                          >
                            <BiCheck size={25} />
                            <p>Accept</p>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="">
                      {u.isCancel ? (
                        <div className="todays-cancel-btn">
                          <button className="cancel-btn">
                            <BiCheck size={30} />
                            <p>Meeting Cancel</p>
                          </button>
                        </div>
                      ) : (
                        <div className="todays-decline-btn">
                          <button
                            className="decline-btn"
                            onClick={() => funDecline(u, "Declined")}
                          >
                            <RiCloseFill size={25} />
                            <p>Decline</p>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
        {/* Modal */}
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Change Status To {titlename}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="row">
                {titlename === "Accept" ? (
                  <div className="col-lg-6">
                    <div className="conference-select">
                      <label>Select Location:</label>
                      <select
                        name="Location"
                        value={selectedData.Location}
                        onChange={(event) => handleChangeLocation(event)}
                      >
                        <option value="0">-- Select --</option>
                        <option value="Conference Hall 1">
                          Conference Hall 1
                        </option>
                        <option value="Conference Hall 2">
                          Conference Hall 2
                        </option>
                        <option value="Conference Hall 3">
                          Conference Hall 3
                        </option>
                        <option value="Buldhana Office">Buldhana Office</option>
                        <option value="Kharghar Office">Kharghar Office</option>
                      </select>
                      {state.errors ? (
                        <div className="error-msg">{state.errors.Location}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {titlename === "Declined" ? (
                  <div className="col-lg-6">
                    <label className="book-label">Remark</label>
                    <input
                      className="form-control"
                      label="Remark"
                      name="Remark"
                      data-name="Remark"
                      value={state.Remark}
                      onChange={handleChangeLocation}
                    />
                    {state.errors ? (
                      <div className="error-msg">{state.errors.Remark}</div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="model-close-btn"
              onClick={() => {
                setShow(false);
              }}
            >
              Close
            </button>
            <button
              className="model-book-btn"
              onClick={SaveData}
              // onClick={handleClose}
            >
              Submit
            </button>
          </Modal.Footer>
        </Modal>
        {/* <h5>Yesterdays Appointment</h5>

        <div className="Todays-app-section">
          <div className="row">
            {data.map((d) => (
              <div className="col-xl-4 col-lg-6 col-md-12 col-sm-12" key={d.AppoinmentID}>
                <div className="todays-outer">
                  <div className="todays-inner-wrapper">
                    <p>Name: {d.FullName}</p>
                    <p>Mobile: {d.Mobile}</p>
                    <p>Interest In: {d.ConsultationInterest}</p>
                    <p>Date & Time: {d.MeetingDate}</p>
                    <p style={{ display: "flex", alignItems: "center" }}>
                      Status:
                      <p
                        style={{
                          color: "green",
                          paddingLeft: "4px",
                          fontWeight: "600",
                        }}
                      >
                        Meeting Confirmed
                      </p>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>
    </>
  );
};

export default TodaysAppointment;
