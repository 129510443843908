import "../Styles/List.css";
import { MdDelete } from "react-icons/md";
import { AiFillEdit, AiFillEye, AiFillSchedule } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";

import React, { useMemo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAppointmentplannerDetail,
  getAppointmentplannerDelete,
  getAppointmentplannerDetailByID,
  getListFilter,
  getAppointmentWherecondition,
  useQuery,
  getUpdateStatusForMeeting,
  iuappointmentplanner,
} from "../config/api";
import { check_islogin, convertTime } from "../config/constant";
import { CSVLink, CSVDownload } from "react-csv";
import Table from "../Components/table/Index";
import Pagination from "../Components/table/Pagination";

const List = () => {
  const [show, setShow] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setShow(false);

  const [listdata, setListData] = useState([]);
  const [smShow, setSmShow] = useState(false);
  const [mode, setMode] = useState("Add");
  const [editid, setEditID] = useState();
  const [time, setTime] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [showduration, setShowDuration] = useState(false);
  const [showlocation, setShowLocation] = useState(false);
  const [data, setData] = useState({
    ID: "",
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    ConsultationInterest: "",
    Description: "",
    CreatedBy: sessionStorage.getItem("AdminID"),
    MeetingDate: "",
    MeetingTime: "",
    Status: "",
    Location: "",
    Remark: "",
  });
  const query = useQuery();
  const t = query.get("t");
  const a = query.get("a");

  const [state, setState] = useState({
    SearchText: "",
    Date: "0",
    Time: "0",
    Status: a ? a : "0",
    Type: t ? t : "0",
    CreatedDateFrom: "0",
    CreatedDateTo: "0",
  });

  const [viewdata, setViewData] = useState({});
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTableData, setcurrentTableData] = useState([]);
  let PageSize = 10;
  console.log(state);
  const csvHeaders = [
    { label: "Appoinment ID", key: "ID" },
    { label: "FullName", key: "FullName" },
    // { label: 'First Name', key: 'FirstName' },
    // { label: 'Last Name', key: 'LastName' },
    { label: "Mobile", key: "Mobile" },
    { label: "EmailID", key: "EmailID" },
    { label: "Consultation Interest", key: "ConsultationInterest" },
    { label: "Location", key: "Location" },
    { label: "MeetingDate", key: "MeetingDate" },
    { label: "MeetingTime", key: "MeetingTime" },
    { label: "Status", key: "Status" },
  ];

  const handleChanged = (e) => {
    if (e.target.name === "Type") {
      if (e.target.value === "5") {
        setShowDuration(true);
      } else {
        setShowDuration(false);
      }
    }
    if (e.target.value) {
      setState({ ...state, [e.target.name]: e.target.value });
    } else {
      setState({ ...state, [e.target.name]: "0" });
    }
  };
  function handlechange(e) {
    if (e.target.name === "Status" && e.target.value === "Complete") {
      setShowLocation(true);
    } else {
      setShowLocation(false);
    }

    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }
  const handleShow = (data) => {
    console.log("data", data);
    setShow(true);
    setViewData(data);
  };

  const handleSubmit = async () => {
    debugger;
    console.log("data", data);
    await iuappointmentplanner(data)
      .then((response) => {
        debugger;
        if (response[0]?.data === "date and time exist") {
          //errors["EmailID"] = "This Email Already exists...";
          alert("Date/Time Already Booked. Please select another date/time");
        } else {
          alert("Appointment Saved Successfully");
          setSmShow(false);
          bindData();
        }
      })
      .catch((error) => {
        alert(error);
      });
  };
  useEffect(() => {
    // check_islogin();
    bindData();
    // SearchData();
  }, []);

  // const currentTableData = useMemo(() => {
  //   const firstPageIndex = (currentPage - 1) * PageSize;
  //   const lastPageIndex = firstPageIndex + PageSize;
  //   return dataList.slice(firstPageIndex, lastPageIndex);
  // }, [currentPage]);
  async function bindData() {
    await getAppointmentWherecondition(state)
      .then((response) => {
        debugger;
        console.log("responseeeeeee------------------------>", state);
        if (response.length > 0) {
          setDataList(response);
          const firstPageIndex = (currentPage - 1) * PageSize;
          const lastPageIndex = firstPageIndex + PageSize;
          setcurrentTableData(response.slice(firstPageIndex, lastPageIndex));
        } else {
          setDataList([]);
          setFilteredData([]);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  // useEffect(() => {
  //   // Define the API call function
  //   const fetchSearchResults = async (keyword) => {
  //     try {
  //       const response = await getListFilter(searchText);
  //       setDataList(response);
  //       const filtered = response.filter((item) => {
  //         // Perform your search logic here
  //         // For example, check if the item's Village Name contains the search keyword
  //         return item.ConsultationInterest.toLowerCase().includes(
  //           searchText.toLowerCase()
  //         );
  //       });
  //       setFilteredData(filtered);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   // Call the API when the searchText changes
  //   if (searchText !== "") {
  //     fetchSearchResults();
  //   } else {
  //     bindData();
  //   }
  // }, [searchText]);

  const handleInputChange = (e) => {
    setSearchText(e.target.value);
  };

  //   async function SearchData(e) {
  // ;
  //     e.preventDefault();
  //     let searchtext = e.target.value;

  //     if (searchtext !== "") {
  //       await getListFilter(searchtext)
  //         .then((response) => {;
  //           setDataList(response.data[0]);
  //         })
  //         .catch((error) => {
  //           alert(error);
  //         });
  //     } else {
  //       bindData();
  //     }
  //   }

  async function DeleteData(id) {
    debugger;
    if (window.confirm("Are you sure delete data?")) {
      await getAppointmentplannerDelete(id)
        .then((response) => {
          alert("Data Deleted Successfully");
          bindData();
        })
        .catch((error) => {
          alert(error);
        });
    }
  }

  function update(id) {
    setEditID(id);
    setSmShow(true);
    setMode("Edit");
  }
  console.log(state);
  return (
    <>
      <div className="list-wrapper">
        <div className="list-title">
          <h3>Appointments List</h3>
        </div>
        <div className="List_header">
          <div className="search-text-wrapper">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="search-text-div all-search-text-box">
                  <label>Search here..</label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="search by name,mobile,email"
                    name="SearchText"
                    onChange={handleChanged}
                    value={state.SearchText === "0" ? "" : state.SearchText}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="search-text-div date-time-div">
                  <label>Date</label>
                  {/* <input
                    className="form-control"
                    type="date"
                    placeholder="search by Date"
                    name="Date"
                    onChange={handleChanged}
                    value={state.Date}
                  /> */}
                  <DatePicker
                    className="Form_input"
                    calendarClassName="tren-pembayaran__wrapper__datepicker"
                    name="Date"
                    //minDate={new Date()}
                    // maxDate={new Date(new Date().setMonth((new Date()).getMonth() + 2))}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="Meeting Date"
                    selected={
                      state.Date && state.Date !== "0"
                        ? new Date(state.Date)
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        let d = new Date(date);
                        let dd =
                          d.getFullYear() +
                          "-" +
                          (d.getMonth() + 1) +
                          "-" +
                          d.getDate();
                        setState({
                          ...state,
                          Date: dd,
                        });
                      } else {
                        setState({
                          ...state,
                          Date: null,
                          errors: { ...state.errors, Date: "" },
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="search-text-div date-time-div">
                  <label>Time</label>
                  {/* <input
                    className="form-control"
                    type="time"
                    placeholder="search by time"
                    name="Time"
                    onChange={handleChanged}
                    value={state.Time}
                  /> */}
                  <DatePicker
                    className="Form_input"
                    calendarClassName="tren-pembayaran__wrapper__datepicker"
                    name="Time"
                    // minDate={new Date()}
                    // maxDate={new Date(new Date().setMonth((new Date()).getMonth()+2))}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    placeholderText="Meeting Time"
                    //selected={new Date("2013-08-27 2:50 PM")}
                    selected={
                      state.Time &&
                      state.Time !== "0" &&
                      state.Date &&
                      state.Date !== "0"
                        ? new Date(state.Date + " " + state.Time)
                        : null
                    }
                    onChange={(time) => {
                      debugger;
                      if (!state?.Date || state?.Date === "0") {
                        alert("Meeting Date is Required");
                      } else {
                        var hours = time.getHours();
                        var minutes = time.getMinutes();
                        var strTime = hours + ":" + minutes;
                        setTime(strTime);
                        setState({
                          ...state,
                          Time: strTime,
                        });
                      }
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="search-text-div">
                  <label>Status</label>
                  <select
                    className="form-control"
                    type="text"
                    placeholder="search by status"
                    name="Status"
                    onChange={handleChanged}
                    value={state.Status}
                  >
                    <option value="0">Select</option>
                    <option value="Pending">Pending</option>
                    <option value="Complete">Complete</option>
                    <option value="Declined">Declined</option>
                  </select>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <div className="search-text-div">
                  <label>Duration</label>
                  <select
                    className="form-select"
                    name="Type"
                    onChange={handleChanged}
                    value={state.Type}
                  >
                    <option value="0">Select</option>
                    <option value="1">Daily</option>
                    <option value="2">Last 7 days</option>
                    <option value="3">Last Month</option>
                    <option value="4">Last Year</option>
                    <option value="5">Date Wise</option>
                  </select>
                </div>
              </div>
              {showduration ? (
                <>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <div className="search-text-div">
                      <label className="control-label">Created From</label>
                      {/* <input
                        type="date"
                        name="CreatedDateFrom"
                        onChange={handleChanged}
                        className="form-control"
                        placeholder="Enter created"
                      /> */}
                      <DatePicker
                        className="Form_input"
                        calendarClassName="tren-pembayaran__wrapper__datepicker"
                        name="CreatedDateFrom"
                        // minDate={new Date()}
                        // maxDate={new Date(new Date().setMonth((new Date()).getMonth() + 2))}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Meeting Date"
                        selected={
                          state.CreatedDateFrom
                            ? new Date(state.CreatedDateFrom)
                            : null
                        }
                        onChange={(date) => {
                          if (date) {
                            let d = new Date(date);
                            let dd =
                              d.getFullYear() +
                              "-" +
                              (d.getMonth() + 1) +
                              "-" +
                              d.getDate();
                            setState({
                              ...state,
                              CreatedDateFrom: dd,
                            });
                          } else {
                            setState({
                              ...state,
                              CreatedDateFrom: null,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12">
                    <div className="search-text-div">
                      <label className="control-label">Created To</label>
                      {/* <input
                        type="date"
                        name="CreatedDateTo"
                        onChange={handleChanged}
                        className="form-control"
                        placeholder="Enter created"
                      /> */}
                      <DatePicker
                        className="Form_input"
                        calendarClassName="tren-pembayaran__wrapper__datepicker"
                        name="CreatedDateTo"
                        // minDate={new Date()}
                        // maxDate={new Date(new Date().setMonth((new Date()).getMonth() + 2))}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Meeting Date"
                        selected={
                          state.CreatedDateTo
                            ? new Date(state.CreatedDateTo)
                            : null
                        }
                        onChange={(date) => {
                          if (date) {
                            let d = new Date(date);
                            let dd =
                              d.getFullYear() +
                              "-" +
                              (d.getMonth() + 1) +
                              "-" +
                              d.getDate();
                            setState({
                              ...state,
                              CreatedDateTo: dd,
                            });
                          } else {
                            setState({
                              ...state,
                              CreatedDateTo: null,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="list-filters">
            {/* <div className="list-search">
              <input
                type="text"
                className="Filter_Input"
                placeholder="Search here..."
                value={searchText}
                onChange={handleInputChange}
              />
            </div> */}
            <div>
              <button
                className="search-btn btn-download bg-success "
                onClick={() => {
                  bindData();
                }}
              >
                Search
              </button>
            </div>
            <div className="list-download">
              <div>
                <CSVLink
                  data={dataList}
                  headers={csvHeaders}
                  filename="data.csv"
                  className="btn-download"
                >
                  Download Data
                </CSVLink>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <table className="table styled-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Full Name</th>
                <th>Mobile No</th>
                <th>Interest In</th>
                <th>Booking Location</th>
                <th>Location</th>
                <th>Date</th>
                <th>Time</th>
                <th>Status</th>
                <th>Remark</th>
                <th>Action</th>
              </tr>
            </thead>
            {currentTableData.map((item) => (
              <>
                <tbody>
                  <tr>
                    <td>{item.ID}</td>
                    <td>{item.FullName}</td>
                    <td>{item.Mobile}</td>
                    <td>{item.ConsultationInterest}</td>
                    <td>{item.BLocation}</td>
                    <td>{item.Location}</td>
                    <td>{item.MeetingDate}</td>
                    <td>{item.MeetingTime}</td>
                    <td>{item.Status}</td>
                    <td>{item.Remark}</td>
                    <td className="action-btn">
                      <AiFillEdit
                        className="Schedule_Icon"
                        //  onClick={(e) => handledata(e,item)}
                        // onClick={UpdateData}
                        // onClick={() => UpdateData(item.AppoinmentID)}
                        // onClick={handledata}
                        onClick={() => {
                          debugger;
                          setTime(item.MeetingTime);
                          setData({
                            ...data,
                            ID: item.ID,
                            MeetingDate: item.MeetingDate,
                            MeetingTime: convertTime(item.MeetingTime),
                            Status: item.Status,
                            Location: item.Location,
                            Remark: item.Remark,
                          });
                          setSmShow(true);
                        }}
                      />
                      <AiFillEye
                        className="Eye_Icon"
                        //  onClick={(e) => handledata(e,item)}
                        // onClick={UpdateData}
                        // onClick={() => UpdateData(item.AppoinmentID)}
                        // onClick={handledata}
                        onClick={() => handleShow(item)}
                      />
                      <MdDelete
                        className="Delete_Icon"
                        onClick={() => DeleteData(item.ID)}
                      />
                    </td>
                  </tr>
                </tbody>
              </>
            ))}
          </table>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={dataList.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="List_details">
            <h4>Appointment Customer Details</h4>
            <br />
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Full Name</h5>
                <input value={viewdata.FullName} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Mobile Number</h5>
                <input value={viewdata.Mobile} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Email-Id</h5>
                <input value={viewdata.EmailID} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Booking Location</h5>
                <input value={viewdata.BLocation} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Interest In</h5>
                <input value={viewdata.ConsultationInterest} disabled />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h5>Location</h5>
                <input value={viewdata.Location} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h5>Date</h5>
                <input value={viewdata.MeetingDate} disabled />
              </div>

              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h5>Date and Time</h5>
                <input value={viewdata.MeetingTime} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                <h5>Status</h5>
                <input value={viewdata.Status} disabled />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Additional Infomation</h5>
                <textarea className="clstxtarea" disabled>
                  {viewdata.Description}
                </textarea>
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h5>Remark</h5>
                <input value={viewdata.Remark} disabled />
              </div>
            </div>
            <div className="row">
              <div className="Model_Footer">
                <button
                  className="btn view-btn btn-primary"
                  onClick={handleClose}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          show={smShow}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="List_details">
            <h4>Edit Appointment Customer Details</h4>
            <br />
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Meeting Date</h6>
                {/* <input
                  type="date"
                  className="Form_input"
                  label="MeetingDate"
                  name="MeetingDate"
                  data-name="MeetingDate"
                  value={data.MeetingDate}
                  onChange={handlechange}
                /> */}
                <DatePicker
                  className="Form_input"
                  calendarClassName="tren-pembayaran__wrapper__datepicker"
                  name="MeetingDate"
                  // minDate={new Date()}
                  // maxDate={new Date(new Date().setMonth((new Date()).getMonth() + 2))}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Meeting Date"
                  selected={
                    data.MeetingDate ? new Date(data.MeetingDate) : null
                  }
                  onChange={(date) => {
                    if (date) {
                      let d = new Date(date);
                      let dd =
                        d.getFullYear() +
                        "-" +
                        (d.getMonth() + 1) +
                        "-" +
                        d.getDate();
                      setData({
                        ...data,
                        MeetingDate: dd,
                      });
                    } else {
                      setData({
                        ...data,
                        MeetingDate: null,
                      });
                    }
                  }}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Meeting Time</h6>
                {/* <input
                  type="time"
                  className="Form_input"
                  label="MeetingTime"
                  name="MeetingTime"
                  data-name="MeetingTime"
                  value={convertTime(data.MeetingTime)}
                  onChange={handlechange}
                /> */}
                <DatePicker
                  className="Form_input"
                  calendarClassName="tren-pembayaran__wrapper__datepicker"
                  name="MeetingTime"
                  // minDate={new Date()}
                  // maxDate={new Date(new Date().setMonth((new Date()).getMonth()+2))}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="MeetingTime"
                  dateFormat="h:mm aa"
                  placeholderText="Meeting Time"
                  //selected={new Date("2013-08-27 2:50 PM")}
                  selected={
                    time
                      ? new Date(data.MeetingDate + " " + data.MeetingTime)
                      : null
                  }
                  onChange={(time) => {
                    debugger;

                    if (!data?.MeetingDate) {
                      alert("Meeting Date is Required");
                      setData({
                        ...data,
                      });
                    } else {
                      var hours = time.getHours();
                      var minutes = time.getMinutes();
                      var strTime = hours + ":" + minutes;
                      setData({
                        ...data,
                        MeetingTime: strTime,
                      });
                    }
                  }}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Status</h6>
                <select
                  name="Status"
                  className="Form_input"
                  onChange={handlechange}
                  value={data.Status}
                >
                  <option value="0">Select</option>
                  <option value="Pending">Pending</option>
                  <option value="Complete">Complete</option>
                  <option value="Declined">Declined</option>
                </select>
              </div>
              {showlocation ? (
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <h6 className="booking-label">Location</h6>
                  <select
                    name="Location"
                    className="Form_input"
                    onChange={handlechange}
                    value={data.Location}
                  >
                    <option value="0">-- Select --</option>
                    <option value="Conference Hall 1">Conference Hall 1</option>
                    <option value="Conference Hall 2">Conference Hall 2</option>
                    <option value="Conference Hall 3">Conference Hall 3</option>
                    <option value="Buldhana Office">Buldhana Office</option>
                    <option value="Kharghar Office">Kharghar Office</option>
                  </select>
                </div>
              ) : (
                ""
              )}
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Remark</h6>
                <input
                  type="text"
                  className="Form_input"
                  label="Remark"
                  name="Remark"
                  data-name="Remark"
                  value={data.Remark}
                  onChange={handlechange}
                />
              </div>
            </div>
            <div className="row">
              <div className="Model_Footer">
                <button
                  className="btn view-btn btn-danger text-white m-2"
                  onClick={() => {
                    setSmShow(false);
                  }}
                >
                  Close
                </button>
                <button
                  className="btn view-btn btn-success m-2"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default List;
