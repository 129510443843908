//import React from 'react'
import React, { useEffect, useState } from 'react'
import {
    ClientloginUpdate
  } from "../config/api";


function ClientProfile() {

    const [data, setData] = useState({ 
        oldPassword: '',
          newPassword: '',
          newConfirmPassword: ''
      })
    
      const handleChange = (e) => {
          setData({...data,[e.target.name]: e.target.value})
      }
    
      const handleReset = () => {
          setData({
            oldPassword: '',
              newPassword: '',
              newConfirmPassword: '',
              UserName:''
          })
      }
    //OldPassword
    //UserName
    
      const handleChangePassword = async (e) => {
        
        const form = e.currentTarget;
        e.preventDefault();
        await ClientloginUpdate({
           "UserName":sessionStorage.getItem("EmailID"),
           "OldPassword":data.newPassword
        })
            .then((response) => {;
                console.log(response)
            })
           
            .catch((error) => {
                alert(error);
            });
            alert("Your Login Credentials Change Successfully....")
            window.location.reload(true);
    }


    
    return (
        <>
            <div className='Main_Container'>
                <h5>Manage</h5>
                <div className='Setting-form-div'>
                    <h4>Change Login Credentials</h4>
                    <div>
                        <div>
                            <h6>Username</h6>
                            <input type='text'
                             placeholder='Enter User Name'
                              className='Setting_inputfield' 
                              value={data.UserName}
                              onChange={handleChange} 
                              id="UserName"
                              //type="email"
                              //required
                              name="UserName"
                              />
                        </div>
                        <div>
                            <h6>Old password</h6>
                            <input type='text'
                             placeholder='Old Password' 
                             className='Setting_inputfield' 
                             value={data.oldPassword}
                             onChange={handleChange} 
                             name="oldPassword" 
                             />
                        </div>
                        <div>
                            <h6>New password</h6>
                            <input type='text'
                             placeholder='New Password'
                              className='Setting_inputfield'
                              value={data.newPassword}
                              onChange={handleChange}
                              name="newPassword"
                               />
                        </div>
                        <div>
                            <h6>Confirm password</h6>
                            <input type='text'
                             placeholder='Confirm New Password' 
                             className='Setting_inputfield'
                             value={data.newConfirmPassword}
                             onChange={handleChange}
                             name="newConfirmPassword"
                              />
                        </div>
                        <div className='Button_div'>
                            <button className='button-28' onClick={handleReset}>Reset</button>
                            <button className='button-28' onClick={handleChangePassword}>Change</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ClientProfile