//import React from "react";
import "../Styles/Booking.css";

import React, { useEffect, useState } from "react";
import {
  getUserProfileDetailByID,
  iuSMSDetail,
  iuappointmentplanner,
} from "../config/api";
import {
  check_islogin,
  sendSMS,
  smsService,
  smsTemplate,
} from "../config/constant";
import { GrPowerReset } from "react-icons/gr";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";

const Booking = () => {
  const aCreatedBy =
    sessionStorage.getItem("Type") === "admin" ||
    sessionStorage.getItem("Type") === "receiptionist"
      ? sessionStorage.getItem("AdminID")
      : sessionStorage.getItem("UserID");
  const [state, setState] = useState({
    ID: "0",
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmailID: "",
    Mobile: "",
    BLocation: "",
    ConsultationInterest: "",
    MeetingDate: "",
    MeetingTime: "",
    Description: "",
    Status: "Pending",
    Type: sessionStorage.getItem("Type"),
    CreatedBy:
      sessionStorage.getItem("Type") === "admin" ||
      sessionStorage.getItem("Type") === "receiptionist"
        ? sessionStorage.getItem("AdminID")
        : sessionStorage.getItem("UserID"),
    errors: [],
  });
  function handlechange(e) {
    debugger;
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  }
  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      debugger;
      await iuappointmentplanner(state)
        .then((response) => {
          if (response[0]?.data === "date and time exist") {
            //errors["EmailID"] = "This Email Already exists...";
            alert("Date/Time Already Booked. Please select another date/time");
          } else if (response[0]?.data === "na") {
            //errors["EmailID"] = "This Email Already exists...";
            alert("Date/Time Already Booked. Please select another date/time");
          } else {
            alert("Your Appointment Saved Successfully");
            const p = response[0]?.Mobile;
            const d = response[0]?.MeetingDate;
            const t = response[0]?.MeetingTime;
            const a = smsTemplate(state.ConsultationInterest, d, t);
            smsService.send({
              to: state.Mobile, // + data.Mobile,
              msg: a,
            }).then((tempdata)=>{debugger
              iuSMSDetail({
                CustomerID: aCreatedBy,
                Mobile: p,
                Message: a,
                Status: tempdata.data,
                CreatedBy: aCreatedBy,
              });
            if (tempdata.data?.split(" | ")[0] === "SUBMIT_SUCCESS") {
                
              alert("Message Send To your mobile please check");
                    ResetState();
                    window.location.reload(true);
              // sendSMS(p, a).then((res) => {
              //   console.log(res);
              //   iuSMSDetail({
              //     CustomerID: aCreatedBy,
              //     Mobile: p,
              //     Message: a,
              //     Status: res.data,
              //     CreatedBy: aCreatedBy,
              //   }).then((response_a) => {
              //     if (response_a.length > 0) {
              //       alert("Message Send To your mobile please check");
              //       ResetState();
              //       window.location.reload(true);
              //     }
              //   });
              // });
            } else {
              alert("Message Not Sent. Please contact to admin");
              //alert("Message Send To your mobile please check");
              ResetState();
              window.location.reload(true);
            }
          });
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }
  async function bindData() {
    await getUserProfileDetailByID(sessionStorage.getItem("UserID"))
      .then((response) => {
        debugger;
        if (response) {
          setState({
            ...state,
            Salutation: response[0].Salutation,
            FirstName: response[0].FirstName,
            MiddleName: response[0].MiddleName,
            LastName: response[0].LastName,
            EmailID: response[0].EmailID,
            Mobile: response[0].Mobile,
          });
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  async function ResetState() {
    setState({
      ID: "0",
      Salutation: "",
      FirstName: "",
      MiddleName: "",
      LastName: "",
      EmailID: "",
      Mobile: "",
      BLocation:"",
      ConsultationInterest: "",
      MeetingDate: "",
      MeetingTime: "",
      Description: "",
      Status: "Pending",
      Type: sessionStorage.getItem("Type"),
      CreatedBy:
        sessionStorage.getItem("Type") === "admin" ||
        sessionStorage.getItem("Type") === "receiptionist"
          ? sessionStorage.getItem("AdminID")
          : sessionStorage.getItem("UserID"),
      errors: [],
    });
  }

  function validate() {
    let errors = {};
    let IsValid = true;
    //const Mobilenumber = /^[7-9][0-9]/;
    const Mobilenumber = /^\d{10}$/;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    if (!state.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    } else if (!state.FirstName.match(regex_name)) {
      IsValid = false;
      errors["FirstName"] = "Please Enter Valid Name";
    } else {
      errors["FirstName"] = "";
    }

    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    } else if (!state.LastName.match(regex_name)) {
      IsValid = false;
      errors["LastName"] = "Please Enter Valid Name";
    } else {
      errors["LastName"] = "";
    }
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    } else if (!Mobilenumber.test(state.Mobile)) {
      IsValid = false;
      errors["Mobile"] = "Please Enter valid Mobile Number";
    } else {
      errors["Mobile"] = "";
    }
    // if (!state?.EmailID) {
    //   IsValid = false;
    //   errors["EmailID"] = "Email ID is Required";
    // } else if (!state.EmailID.match(regex_email)) {
    //   IsValid = false;
    //   errors["EmailID"] = "Please Enter valid Email id";
    // } else {
    //   errors["EmailID"] = "";
    // }

    if (!state?.ConsultationInterest) {
      IsValid = false;
      errors["ConsultationInterest"] = "Consultation Interest is Required";
    }
    if (!state?.MeetingDate) {
      IsValid = false;
      errors["MeetingDate"] = "Meeting Date is Required";
    } else {
      errors["MeetingDate"] = "";
    }
    if (!state?.MeetingTime) {
      IsValid = false;
      errors["MeetingTime"] = "Meeting Time is Required";
    } else {
      errors["MeetingTime"] = null;
    }

    if (!state?.Description) {
      IsValid = false;
      errors["Description"] = "Description is Required";
    } else {
      errors["EmailID"] = "";
    }
    if (!state?.BLocation) {
      IsValid = false;
      errors["BLocation"] = "Location is Required";
    } else {
      errors["BLocation"] = "";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  useEffect(() => {
    if (sessionStorage.getItem("Type") === "user") {
      bindData();
    }
  }, []);
  console.log("Booking", state);
  return (
    <>
      <div className="Main_Container">
        <h5>Get Appointment</h5>
        <div className="booking-wrapper">
          <div className="Form_Div">
            <div className="Model-div-reset">
              <h6 className="model-sub-heading ">
                Fill the form to book an appointment
              </h6>
              <GrPowerReset
                className="Reset-Button"
                size={23}
                onClick={ResetState}
                // onClick={()=>alert('heelo')}
              />
            </div>
            <div className="row Name_Div">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">First Name</h6>
                <input
                  type="text"
                  className="Form_input"
                  label="FirstName"
                  name="FirstName"
                  placeholder="First Name"
                  data-name="FirstName"
                  value={state.FirstName}
                  onChange={handlechange}
                />
                {state.errors ? (
                  <div className="error-msg">{state.errors.FirstName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Last Name </h6>
                <input
                  type="text"
                  className="Form_input"
                  label="LastName"
                  name="LastName"
                  data-name="LastName"
                  placeholder="Last Name"
                  value={state.LastName}
                  onChange={handlechange}
                />
                {state.errors ? (
                  <div className="error-msg">{state.errors.LastName}</div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row Name_Div">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Contact Number</h6>
                <input
                  type="text"
                  className="Form_input"
                  label="Mobile"
                  name="Mobile"
                  data-name="Mobile"
                  placeholder="Mobile Number"
                  value={state.Mobile}
                  maxLength={10}
                  onChange={handlechange}
                />

                {state.errors ? (
                  <div className="error-msg">{state.errors.Mobile}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">E-mail</h6>
                <input
                  type="email"
                  className="Form_input"
                  label="EmailID"
                  name="EmailID"
                  data-name="EmailID"
                  placeholder="Email Address"
                  value={state.EmailID}
                  onChange={handlechange}
                />
                {state.errors ? (
                  <div className="error-msg">{state.errors.EmailID}</div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row Name_Div">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 Custom_Select">
                <h6 className="booking-label">Booking Location</h6>
                <select
                  className="Form_input"
                  name="BLocation"
                  onChange={handlechange}
                  value={state.BLocation}
                >
                  <option value="0">Select</option>
                  <option value="Buldhana">Buldhana Office</option>
                  <option value="Mumbai">Mumbai Office</option>
                </select>
                {state.errors ? (
                  <div className="error-msg">{state.errors.BLocation}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 Custom_Select">
                <h6 className="booking-label">Consultation Interest</h6>
                <select
                  className="Form_input"
                  name="ConsultationInterest"
                  onChange={handlechange}
                  value={state.ConsultationInterest}
                >
                  <option value="0">Select</option>
                  <option value="Abhita Land Solutions Pvt Ltd">
                    Abhita Land Solutions Pvt Ltd
                  </option>
                  <option value="Abhita Finance Advisor Pvt Ltd">
                    Abhita Finance Advisor Pvt Ltd
                  </option>
                  <option value="Abhita Agro Industries">
                    Abhita Agro Industries
                  </option>
                  <option value="Abhita Films Production Pvt Ltd">
                    Abhita Films Production Pvt Ltd
                  </option>
                  <option value="Abhita Properties Pvt Ltd ">
                    Abhita Properties Pvt Ltd{" "}
                  </option>
                  <option value="Abhita World Trade PVT Ltd">
                    Abhita World Trade Pvt Ltd
                  </option>
                  <option value="Abhita Fashion PVT. Ltd">
                    Abhita Fashion Pvt Ltd
                  </option>
                  <option value="Abhita UAV Infratech Service Pvt. Ltd">
                    Abhita UAV Infratech Service Pvt Ltd
                  </option>
                  <option value="Social Concerns">Social Concerns</option>
                  <option value="Other">Other</option>
                </select>
                {state.errors ? (
                  <div className="error-msg">
                    {state.errors.ConsultationInterest}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row Name_Div">
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Meeting Date</h6>
                {/* <input
                  type="date"
                  className="Form_input"
                  label="MeetingDate"
                  name="MeetingDate"
                  data-name="MeetingDate"
                  value={state.MeetingDate}
                  onChange={handlechange}
                  min="2018-08-26"
                /> */}
                <DatePicker
                  className="Form_input"
                  calendarClassName="tren-pembayaran__wrapper__datepicker"
                  name="MeetingDate"
                  minDate={new Date()}
                  maxDate={
                    new Date(new Date().setMonth(new Date().getMonth() + 2))
                  }
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Meeting Date"
                  selected={
                    state.MeetingDate ? new Date(state.MeetingDate) : null
                  }
                  onChange={(date) => {
                    if (date) {
                      let d = new Date(date);
                      let dd =
                        d.getFullYear() +
                        "-" +
                        (d.getMonth() + 1) +
                        "-" +
                        d.getDate();
                      setState({
                        ...state,
                        MeetingDate: dd,
                        errors: { ...state.errors, MeetingDate: "" },
                      });
                    } else {
                      setState({
                        ...state,
                        MeetingDate: null,
                        errors: { ...state.errors, MeetingDate: "" },
                      });
                    }
                  }}
                />
                {state.errors ? (
                  <div className="error-msg">{state.errors.MeetingDate}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <h6 className="booking-label">Meeting Time</h6>
                {/* <input
                  type="time"
                  className="Form_input"
                  label="MeetingTime"
                  name="MeetingTime"
                  data-name="MeetingTime"
                  value={state.MeetingTime}
                  onChange={handlechange}
                /> */}
                <DatePicker
                  className="Form_input"
                  calendarClassName="tren-pembayaran__wrapper__datepicker"
                  name="MeetingTime"
                  // minDate={new Date()}
                  // maxDate={new Date(new Date().setMonth((new Date()).getMonth()+2))}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  placeholderText="Meeting Time"
                  //selected={new Date("2013-08-27 2:50 PM")}
                  selected={
                    state.MeetingTime
                      ? new Date(state.MeetingDate + " " + state.MeetingTime)
                      : null
                  }
                  onChange={(time) => {
                    debugger;
                    let errors = {};
                    let IsValid = true;
                    if (!state?.MeetingDate) {
                      IsValid = false;
                      errors["MeetingDate"] = "Meeting Date is Required";
                      setState({
                        ...state,
                        errors: errors,
                      });
                      return IsValid;
                    } else {
                      var hours = time.getHours();
                      var minutes = time.getMinutes();
                      var strTime = hours + ":" + minutes;
                      setState({
                        ...state,
                        MeetingTime: strTime,
                      });
                    }
                  }}
                />
                {state.errors ? (
                  <div className="error-msg">{state.errors.MeetingTime}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row Name_Div">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h6 className="booking-label">
                  Additional Information/Comments
                </h6>
                <textarea
                  type="text"
                  className="Form_input"
                  label="Description"
                  name="Description"
                  data-name="Description"
                  value={state.Description}
                  onChange={handlechange}
                ></textarea>
                {state.errors ? (
                  <div className="error-msg">{state.errors.Description}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <button type="button" className="button-32" onClick={SaveData}>
              {" "}
              Make Appointment
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;
