import React, { useState } from "react";
import "../Styles/AppSidebar.css";
import { ICON } from "../Utils";
import { AiFillSetting } from "react-icons/ai";
import { TbLogout } from "react-icons/tb";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BsBookmarkPlus } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx";
import { RiFileList3Line } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { FiSettings } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import { BiMenuAltRight } from "react-icons/bi";
import { MdOutlineEmail, MdFacebook, MdClose } from "react-icons/md";

const AppSidebar = () => {
  const [show, setShow] = useState(false);
  const navi = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => {
    debugger;
    setShow(true);
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
    setShow(false);
  };

  const [activeItem, setActiveItem] = useState(sessionStorage.getItem("ActivePage"));
  const [adminactiveItem, setAdminActiveItem] = useState("admindashboard");
  const handleItemClick = (itemName) => {
    sessionStorage.setItem("ActivePage",itemName);
    setActiveItem(sessionStorage.getItem("ActivePage"));
  };
  const handleAdminItemClick = (itemName) => {
    setAdminActiveItem(itemName);
  };
  console.log(show);
  return (
    <>
      <div className="Sidebar_Container sidebar-mb-hide">
        <div className="Sidebar_Top_Div">
          <img src={ICON.Abhitalogo} className="Sidebar_TopLogo" />
          {/* <h3 className="Logo_Text">
            Abhita <br /> Appointment Planner
          </h3> */}
        </div>
        <div className="Sidebar_Line" />

        <div className="Sidebar_Nav_Div">
          {sessionStorage.getItem("Type") === "admin" ||
          sessionStorage.getItem("Type") === "receiptionist" ? (
            <Link
              to="/app/dashboard"
              className={activeItem === "dashboard" ? "active" : "Sidebar_Nav"}
              onClick={() => handleItemClick("dashboard")}
            >
              <RxDashboard
                className={
                  activeItem === "dashboard" ? "activeIcon" : "Sidebar_Nav_Icon"
                }
              />
              <h6>Dashboard</h6>
            </Link>
          ) : (
            ""
          )}
          {sessionStorage.getItem("Type") === "receiptionist" ||
          sessionStorage.getItem("Type") === "user" ? (
            <Link
              to="/app/booking"
              className={activeItem === "booking" ? "active" : "Sidebar_Nav"}
              onClick={() => handleItemClick("booking")}
            >
              <BsBookmarkPlus
                className={
                  activeItem === "booking" ? "activeIcon" : "Sidebar_Nav_Icon"
                }
              />
              <h6>Booking</h6>
            </Link>
          ) : (
            ""
          )}

          {sessionStorage.getItem("Type") === "admin" ||
          sessionStorage.getItem("Type") === "receiptionist" ? (
            <Link
              to="/app/todays_appointment"
              className={
                activeItem === "todaysappointment" ? "active" : "Sidebar_Nav"
              }
              onClick={() => handleItemClick("todaysappointment")}
            >
              <RiFileList3Line
                className={
                  activeItem === "todaysappointment"
                    ? "activeIcon"
                    : "Sidebar_Nav_Icon"
                }
              />
              <h6>My Appointment</h6>
            </Link>
          ) : (
            ""
          )}

          {/* {sessionStorage.getItem("Type") === "user" ? (
            <Link
              to="/app/appointment_list"
              className={
                activeItem === "appointment_list" ? "active" : "Sidebar_Nav"
              }
              onClick={() => handleItemClick("appointment_list")}
            >
              <RiFileList3Line
                className={
                  activeItem === "appointment_list"
                    ? "activeIcon"
                    : "Sidebar_Nav_Icon"
                }
              />
              <h6>My Appointment</h6>
            </Link>
          ) : (
            ""
          )} */}

          {/* {sessionStorage.getItem("EmailID") === "client@gmail.com" ?
            <Link
              className={activeItem === "profile" ? "active" : "Sidebar_Nav"}
              onClick={() => handleItemClick("profile")}
            >
              <CgProfile
                className={
                  activeItem === "profile" ? "activeIcon" : "Sidebar_Nav_Icon"
                }
              />
              <h6>Profile</h6>
            </Link>
            : ""
          } */}

          {sessionStorage.getItem("Type") === "admin" ||
          sessionStorage.getItem("Type") === "receiptionist" ? (
            <Link
              to="/app/setting"
              className={activeItem === "setting" ? "active" : "Sidebar_Nav"}
              onClick={() => handleItemClick("setting")}
            >
              <FiSettings
                className={
                  activeItem === "setting" ? "activeIcon" : "Sidebar_Nav_Icon"
                }
              />
              <h6>Setting</h6>
            </Link>
          ) : (
            ""
          )}

          {sessionStorage.getItem("Type") === "admin" ||
          sessionStorage.getItem("Type") === "receiptionist" ? (
            <Link
              to="/app/list"
              className={activeItem === "list" ? "active" : "Sidebar_Nav"}
              onClick={() => handleItemClick("list")}
            >
              <HiOutlineClipboardList
                className={
                  activeItem === "list" ? "activeIcon" : "Sidebar_Nav_Icon"
                }
              />
              <h6>List</h6>
            </Link>
          ) : (
            ""
          )}
          <Link
            className={activeItem === "logout" ? "active" : "Sidebar_Nav"}
            onClick={() => {
              handleItemClick("logout");
              handleShowModal();
            }}
          >
            <TbLogout
              className={
                activeItem === "logout" ? "activeIcon" : "Sidebar_Nav_Icon"
              }
            />
            <h6>Logout</h6>
          </Link>
        </div>
       
        <Modal show={showModal} onHide={handleCloseModal}>
          <div className="Logout_Main_div">
            <h5>Are you Sure want to Logout</h5>
            <p>
              you are also logged out from your personal cloud and any local
              data app open is this browser.
            </p>
            <div className="Logout_Button">
              <button className="button-28" onClick={handleCloseModal}>
                No
              </button>
              <button
                className="button-28"
                onClick={() => {
                  sessionStorage.getItem("Type") === "user"
                    ? navi("/")
                    : navi("/login");
                  sessionStorage.clear();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AppSidebar;
