import React, { useState } from "react";
import "../Styles/Login.css";
import { UserforgotPassword } from "../config/api";

function ChangePassword() {
  const [state, setState] = useState({
    EmailID: "",
    Password: "",
    ConfirmPassword: "",
    errors: [],
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      errors: { ...state.errors, [e.target.name]: "" },
    });
  };
  const validate = () => {
    const errors = {};
    let IsValid = true;
    const regex_name = /^[A-Za-z\s]+$/;
    const regex_email = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    const Mobilenumber = /^[7-9][0-9]/;

    if (!state.EmailID) {
      IsValid = false;
      errors["EmailID"] = "email id is required";
    } else if (!state.EmailID.match(regex_email)) {
      IsValid = false;
      errors["EmailID"] = "Please Enter valid Email id";
    } else {
      errors["EmailID"] = "";
    }

    if (!state.Password) {
      IsValid = false;
      errors["Password"] = "password is required";
    }
    if (!state.ConfirmPassword) {
      IsValid = false;
      errors["ConfirmPassword"] = "password is required";
    }

    if (!(state.Password === state.ConfirmPassword)) {
      IsValid = false;
      errors["ConfirmPassword"] = "new and confirm password should be same";
    }

    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("submit-->", state);
    if (validate()) {
      await UserforgotPassword(state)
        .then((response) => {
          if (
            response.length > 0 &&
            response[0]?.EmailID !== "" &&
            response[0]?.EmailID !== null
          ) {
            alert("Changed Successfully");
            window.location.href = "/";
          } else {
            alert("Try Again...");
          }
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      alert("Please Enter Proper Details..!");
    }
  };
  return (
    <>
      <div>
        <div className="Login_Container">
          <div className="container">
            <div className="form-div-wrapper chnge-pwd-form ">
            <h3 className="page-title">Abhita Appointment Planner</h3>
              <h4 className="chng-pwd-title">Change Password</h4>
              <form className="login-form">
                <div className="input-div">
                  <label htmlFor="email">Email </label>
                  <input
                    id="EmailID"
                    type="email"
                    placeholder="me@example.com"
                    required
                    name="EmailID"
                    value={state.EmailID}
                    onChange={handleChange}
                  />

                  {state.errors ? (
                    <p className="req-errors">{state.errors.EmailID}</p>
                  ) : null}
                </div>
                <div className="input-div">
                  <label htmlFor="password">New Password </label>
                  <input
                    id="Password"
                    placeholder="password"
                    required
                    type="password"
                    name="Password"
                    value={state.Password}
                    onChange={handleChange}
                  />

                  {state.errors ? (
                    <p className="req-errors">{state.errors.Password}</p>
                  ) : null}
                </div>
                <div className="input-div">
                  <label htmlFor="password">Confirm Password </label>
                  <input
                    id="Password"
                    placeholder="confirm password"
                    required
                    type="password"
                    name="ConfirmPassword"
                    value={state.ConfirmPassword}
                    onChange={handleChange}
                  />

                  {state.errors ? (
                    <p className="req-errors">{state.errors.ConfirmPassword}</p>
                  ) : null}
                </div>{" "}
                <div className="btn-div">
                  <button className="btn btn--form" onClick={handleSubmit}>
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
