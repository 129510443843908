//import React, { useState, useContext, useEffect } from "react";
import "../Styles/Dashboard.css";
import { getMonth } from "../Utils/GetMonth";
import { FcCalendar } from "react-icons/fc";
import { HiPlus } from "react-icons/hi";
import { Modal, Button } from "react-bootstrap";
import GlobalContext from "../Utils/GlobalContext";
import EventModal from "../Utils/EventModal";
import Month from "../Utils/Month";
import CalendarHeader from "../Utils/CalenderHeader";
import { GrPowerReset } from 'react-icons/gr'


import React, { useMemo, useEffect, useState, useContext } from "react";
import {
  iuappointmentplanner,
  getUpcomingMeetings,
  getAppointmentplannerDetail,
} from "../config/api";
import Calendar from "react-calendar";
import axios from "axios";
// import { check_islogin } from "../config/constant";
// import { Link } from "react-router-dom";
// import { Col, Row } from "react-bootstrap";
// import Table from "../Components/table/Index";


const Dashboard = () => {


  const [state, setState] = useState({
    "AppointmentID": "0",
    "FirstName": "",
    "LastName": "",
    "Mobile": "",
    "EmailID": "",
    "ConsultationInterest": "",
    "MeetingDate": "",
    "MeetingTime": "",
    "Comments": "",
    "errors": [],
  });


  const [data, setData] = useState([]);

  const columns = useMemo(
    () => [
      {
        Header: "Session Title",
        accessor: "ConsultationInterest",
      },
      {
        Header: "Meeting Date",
        accessor: "MeetingDate",
      },
      {
        Header: "Meeting Time",
        accessor: "MeetingTime",
      },
    ],
    []
  );
  useEffect(() => {
    // check_islogin();
    bindData();
  }, []);

  async function bindData() {
    ;
    await getUpcomingMeetings()
      .then((response) => {
        ;
        if (response.length > 0) {
          setData(response);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [value, onChange] = useState(new Date());

  const [currentTime, setCurrentTime] = useState(new Date().getHours());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().getHours());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  let greeting;
  if (currentTime >= 0 && currentTime < 12) {
    greeting = 'Good Morning';
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }


  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  var today = new Date();
  var date = String(today.getDate()).padStart(2, "0");
  var mm = monthNames[today.getMonth()];
  var yyyy = today.getFullYear();
  let day = weekday[today.getDay()];

  const [currenMonth, setCurrentMonth] = useState(getMonth());
  const { monthIndex, showEventModal } = useContext(GlobalContext);

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex));
  }, [monthIndex]);




  function handlechange(e) {
    setState({ ...state, [e.target.name]: e.target.value });
  }

  async function SaveData() {
    let errors = {};
    //window.location.href = "/app/package/add/itinerary?id=9";
    if (validate()) {
      ;
      await iuappointmentplanner(state)
        .then((response) => {
          if (response.ID !== "exists") {
            alert("Your Appointment Saved Successfully");
            ResetState();
            window.location.reload(true);
            // navigate(
            //   `/app/package/add/itinerary${
            //     location.search.includes("m=e")
            //       ? location.search
            //       : "?id=" + response.ID
            //   }`
            // );
          } else {
            errors["EmailID"] = "This Email Already exists...";
          }
          setState({
            ...state,
            errors: errors,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  }


  async function ResetState() {
    setState({
      AppointmentID: "0",
      FirstName: "",
      LastName: "",
      Mobile: "",
      EmailID: "",
      ConsultationInterest: "",
      MeetingDate: "",
      MeetingTime: "",
      Comments: "",
      CreatedBy: sessionStorage.getItem("UserID"),
      errors: [],
    });
  }


  function validate() {
    let errors = {};
    let IsValid = true;

    if (!state?.FirstName) {
      IsValid = false;
      errors["FirstName"] = "First Name is Required";
    }
    if (!state?.LastName) {
      IsValid = false;
      errors["LastName"] = "Last Name is Required";
    }
    if (!state?.Mobile) {
      IsValid = false;
      errors["Mobile"] = "Mobile is Required";
    }
    if (!state?.EmailID) {
      IsValid = false;
      errors["EmailID"] = "Email ID is Required";
    }
    if (!state?.ConsultationInterest) {
      IsValid = false;
      errors["ConsultationInterest"] = "Consultation Interest is Required";
    }
    if (!state?.MeetingDate) {
      IsValid = false;
      errors["MeetingDate"] = "Meeting Datee is Required";
    }
    if (!state?.MeetingTime) {
      IsValid = false;
      errors["MeetingTime"] = "Meeting Time is Required";
    }
    // if (!state?.Comments) {
    //   IsValid = false;
    //   errors["Comments"] = "Comments is Required";
    // }
    setState({
      ...state,
      errors: errors,
    });
    return IsValid;
  }

  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    // check_islogin();
    bindData();
    // SearchData();
  }, []);

  async function bindData() {
    await getAppointmentplannerDetail()
      .then((response) => {
        console.log('responseeeeeee------------------------>', response)
        if (response.length > 0) {
          setEvents(response);
        }
      })
      .catch((error) => {
        alert(error);
      });

  }

  const handleDateClick = async date => {
    setSelectedDate(date);
    

    const filteredEvents = events.filter(event => {
      const eventDate = new Date(event.MeetingDate); // Assuming 'date' is the property in each event object
      return eventDate.toDateString() === date.toDateString();
    });

    setFilteredEvents(filteredEvents);
  };


  return (
    <>
      <div>
        <h1>Calendar</h1>
        <div className="Dashboard_Header">
          <h5>Hello, {greeting}</h5>
          {/* <div className="Align_Row">
            <FcCalendar size={25} style={{ marginTop: "4px" }} />
            <h5> &nbsp;
              {date}-{mm}-{yyyy}
            </h5>
          </div> */}
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12" >
            <div className="Main_Container">
              <Calendar onClickDay={handleDateClick} />
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-12" >
            <div className="Main_Container">
              <div className="Dashborad_SubHeader">
                <h5><u>Appointment List Show</u><br/> {selectedDate && selectedDate.toDateString()}</h5>
                <div className="Align_Row make_Button"
                  // onClick={ResetState}
                  onClick={() => setShow(true)}
                >
                  <HiPlus size={20} color="#fff" />
                  <h6>Make Appointment</h6>
                </div>
              </div>

              {/* Modal */}
              <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-custom-modal-styling-title">
                    Book Appointment
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <div className="Model-div-reset">
                      <h6 className="model-sub-heading ">
                        Fill the form to book an appointment
                      </h6>
                      <GrPowerReset className="Reset-Button" size={23} onClick={ResetState}
                      />
                    </div>
                    <div>
                      <div className="row">
                        <div className="col-lg-6">
                          <h6 className="book-label">First Name</h6>
                          <input className="input-book"
                            label="FirstName"
                            name="FirstName"
                            data-name="FirstName"
                            value={state.FirstName}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">{state.errors.FirstName}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <h6 className="book-label">Last Name</h6>
                          <input className="input-book"
                            label="LastName"
                            name="LastName"
                            data-name="LastName"
                            value={state.LastName}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">{state.errors.LastName}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row row-spacing">
                        <div className="col-lg-6">
                          <h6 className="book-label">Mobile Number</h6>
                          <input className="input-book"
                            label="Mobile"
                            name="Mobile"
                            data-name="Mobile"
                            value={state.Mobile}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">{state.errors.Mobile}</div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <h6 className="book-label">Email ID</h6>
                          <input className="input-book"
                            label="EmailID"
                            name="EmailID"
                            data-name="EmailID"
                            value={state.EmailID}
                            onChange={handlechange}
                          />
                          {state.errors ? (
                            <div className="error-msg">{state.errors.EmailID}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row row-spacing">
                        <div className="col-lg-6">
                          <h6 className="book-label">Date</h6>
                          <input className="input-book" type="date"
                            label="MeetingDate"
                            name="MeetingDate"
                            data-name="MeetingDate"
                            value={state.MeetingDate}
                            onChange={handlechange}
                          />

                        </div>
                        {state.errors ? (
                          <div className="error-msg">{state.errors.MeetingDate}</div>
                        ) : (
                          ""
                        )}
                        <div className="col-lg-6">
                          <h6 className="book-label">Time</h6>
                          <input className="input-book"
                            type="time"
                            label="MeetingTime"
                            name="MeetingTime"
                            data-name="MeetingTime"
                            value={state.MeetingTime}
                            onChange={handlechange}

                          />
                        </div>
                        {state.errors ? (
                          <div className="error-msg">{state.errors.MeetingTime}</div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="row row-spacing">
                        <div className="col-lg-6">
                          <h6 className="book-label">Consultation Interest</h6>
                          <select className="interest-select"
                            name="ConsultationInterest"
                            onChange={handlechange}
                            value={state.ConsultationInterest}
                          >
                            <option value="0">Select</option>
                            <option value="Abhitaland">Abhita land</option>
                            <option value="Abhitafinance">Abhita finance</option>
                            <option value="Abhitaagro">Abhita agro</option>
                            <option value="AbhitaVAV">Abhita VAV</option>
                            <option value="AbhitaNBFC"> Abhita NBFC</option>
                            <option value="legaVac">legaVac</option>
                          </select>
                          {state.errors ? (
                            <div className="error-msg">{state.errors.ConsultationInterest}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button className="model-close-btn" onClick={handleClose}>
                    Close
                  </button>
                  <button className="model-book-btn"
                    onClick={SaveData}
                  // onClick={handleClose}
                  >
                    Book Appointment
                  </button>
                </Modal.Footer>
              </Modal>

            </div>

            {selectedDate && (
              <div>
                {/* <h2>Events for {selectedDate && selectedDate.toDateString()}</h2> */}
                {filteredEvents.length > 0 ? (
                  <ul>
                    {filteredEvents.map(event => (
                      <li key={event.AppoinmentID}>{event.FullName}</li>
                    ))}
                  </ul>
                ) : (
                  <p>No events found for {selectedDate && selectedDate.toDateString()}.</p>
                )}
              </div>
            )}

          </div>
        </div>
      </div>


    </>
  );
};

export default Dashboard;