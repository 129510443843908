import axios from "axios";
import React from "react";
import { useLocation } from "react-router-dom";
import { baseURL } from "./constant";
export const axiosInstance = axios.create({
  baseURL: baseURL,
});

//import { resolve } from "./resolve.js";
export default () => axios.get("/");




// //https://api.abhitalandsolutions.com
// //http://localhost:5001
// //const API_URL="https://api.legavac.com/api/legavac";
// //const API_URL="http://localhost:5001/api/legavac";
const API_URL = baseURL+"/api/appointmentplanner";

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};


// export async function forgotPassword(state) {
//   return await axiosInstance
//     .post(`${API_URL}/forgotPassword`, state)
//     .then((res) => res.data);
// }

export async function getAdminLoginDetail(state) {
  return await axios
    .post(`${API_URL}/getAdminLoginDetail`, state)
    .then((res) => res.data);
}
export async function getMeetingStatusCount() {
  return await axiosInstance
    .get(`${API_URL}/getMeetingStatusCount`)
    .then((res) => res.data);
}
export async function getUpcomingMeetings() {
  return await axiosInstance
    .get(`${API_URL}/getUpcomingMeetings`)
    .then((res) => res.data);
}
export async function getPendingMeetingsWherecondition(state) {
  return await axiosInstance
    .post(`${API_URL}/getPendingMeetingsWherecondition`,state)
    .then((res) => res.data);
}
export async function getUpdateStatusForMeeting(state) {
  return await axiosInstance
    .post(`${API_URL}/getUpdateStatusForMeeting`, state)
    .then((res) => res.data);
}
export async function getAppointmentWherecondition(state) {
  return await axiosInstance
    .post(`${API_URL}/getAppointmentWherecondition`,state)
    .then((res) => res.data);
}

export async function getAdminPasswordChange(state) {
  return await axiosInstance
    .post(`${API_URL}/getAdminPasswordChange`,state)
    .then((res) => res.data);
}

export async function getMeetingDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getMeetingDetailByID/${id}`)
    .then((res) => res.data);
}
export async function getMeetingDetailByDate(state) {
  return await axiosInstance
    .post(`${API_URL}/getMeetingDetailByDate`,state)
    .then((res) => res.data);
}
export async function iuusermaster(state) {
  return await axiosInstance
    .post(`${API_URL}/iuusermaster`,state)
    .then((res) => res.data);
}
export async function getUserLoginDetail(state) {
  return await axiosInstance
    .post(`${API_URL}/getUserLoginDetail`,state)
    .then((res) => res.data);
}
export async function getMeetingDetailByUserID(state) {
  return await axiosInstance
    .post(`${API_URL}/getMeetingDetailByUserID`,state)
    .then((res) => res.data);
}
export async function UserforgotPassword(state) {
  return await axiosInstance
    .post(`${API_URL}/UserforgotPassword`,state)
    .then((res) => res.data);
}
export async function getUserProfileDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getUserProfileDetailByID/${id}`)
    .then((res) => res.data);
}
export async function iuSMSDetail(state) {
  return await axiosInstance
    .post(`${API_URL}/iuSMSDetail`,state)
    .then((res) => res.data);
}


//appointmentplanner
export async function iuappointmentplanner(state) {
  return await axiosInstance
    .post(`${API_URL}/iuappointmentplanner`, state)
    .then((res) => res.data);
}

export async function getAppointmentplannerForDDL() {
  return await axiosInstance
    .get(`${API_URL}/getAppointmentplannerForDDL`)
    .then((res) => res.data);
}

export async function getAppointmentplannerDetail() {
  return await axiosInstance
    .get(`${API_URL}/getAppointmentplannerDetail`)
    .then((res) => res.data);
}

export async function getAppointmentplannerDelete(id) {
  return await axiosInstance
    .get(`${API_URL}/getAppointmentplannerDelete/${id}`)
    .then((res) => res.data);
}

export async function getAppointmentplannerDetailByID(id) {
  return await axiosInstance
    .get(`${API_URL}/getAppointmentplannerDetailByID/${id}`)
    .then((res) => res.data);
}



export async function getListFilter(searchtext) {
  return await axiosInstance
    .get(`${API_URL}/getListFilter/${searchtext}`)
    .then((res) => res.data)
}

export async function Filter(state) {
  return await axiosInstance
    .post(`${API_URL}/Filter`, state)
    .then((res) => res.data);
}

export async function CancleMeetingStatusUpdate(state) {
  return await axiosInstance
    .post(`${API_URL}/CancleMeetingStatusUpdate`, state)
    .then((res) => res.data);
}

//profile




export async function getMeetingStatus() {
  return await axiosInstance
    .get(`${API_URL}/getMeetingStatus`)
    .then((res) => res.data);
}


export async function getUpcomingMeetingsFromAdminConfirm() {
  return await axiosInstance
    .get(`${API_URL}/getUpcomingMeetingsFromAdminConfirm`)
    .then((res) => res.data);
}



export async function getTodayMeetings() {
  return await axiosInstance
    .get(`${API_URL}/getTodayMeetings`)
    .then((res) => res.data);
}

export async function getCompleteMeetings() {
  return await axiosInstance
    .get(`${API_URL}/getCompleteMeetings`)
    .then((res) => res.data);
}

export async function getPendingMeetings() {
  return await axiosInstance
    .get(`${API_URL}/getPendingMeetings`)
    .then((res) => res.data);
}

export async function getDeclinedMeetings() {
  return await axiosInstance
    .get(`${API_URL}/getDeclinedMeetings`)
    .then((res) => res.data);
}




//ClientloginUpdate
export async function ClientloginUpdate(state) {
  return await axiosInstance
    .post(`${API_URL}/ClientloginUpdate`, state)
    .then((res) => res.data);
}

//AdminloginUpdate
export async function AdminloginUpdate(state) {
  return await axiosInstance
    .post(`${API_URL}/AdminloginUpdate`, state)
    .then((res) => res.data);
}